import m from 'mithril'
import Authors from '../../models/Authors'
import { make_url_friendly } from '../../logic/Formatter'
import Genres from '../../models/Genres'

const s3 = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const author_box = {
    oninit: vnode => {

        if (vnode.attrs.author) {
            vnode.state.author = vnode.attrs.author
        }
        else {
            Authors.load(() => {
                vnode.state.author = Authors.list.find(a => a.code == vnode.attrs.author_code)
            })
        }
    },

    view: vnode => {

        let author = vnode.state.author
        let code = vnode.attrs.author_code || vnode.state.author.code

        return [
            m('a.my_link.author_box',
                {
                    href: '/author/' + make_url_friendly(code),
                },

                author ? m('img', {
                    src: (s3 + (author.profile_pic ? author.code : 'blank') + '.jpg'),
                    style: ((author.profile_pic && author.profile_pic !== true) ? ('object-position:' + author.profile_pic) : '')
                        + '; '
                    ,

                    alt: author ? ('A picture of ' + author.pen_name) : 'A generic silhouette'
                }) : [],
                m('.image_shade', {
                    style: 'background-color:' + (
                        (author && author.genres) ? Genres.list.reduce((acc, next) => { return ((author.genres_spread[next.code] > (author.genres_spread[acc.code] || 0)) ? next : acc) }, Genres.list.find(g => g.code == author.genres[0])).colour : ''
                    )

                }),
                m('.my_link.author_box_name.absolute_centre', author ? author.pen_name : m('.word_shimmer', '-')),

            )
        ]
    }
}

export default author_box