import m, { redraw } from 'mithril'

import Authors from '../../models/Authors'
import Reviews from '../../models/Reviews'

import { do_something_to_style, capitalise_first } from "../../logic/Formatter"
import review_box from '../components/reviews/review_box'
import Genres from '../../models/Genres'

import quote_bar from '../components/quote_bar'
import breadcrumbs from '../components/breadcrumbs'
import story_block from '../components/story_block'
// import small_review_box from '../components/small_review_box'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'
const blank = image_base + 'blank.jpg'

const MAX_BOOKS_TO_SHOW = 4

const Page = {
    oninit: vnode => {

        Authors.load(list => {
            vnode.state.author = Authors.list.find(a => a.code == vnode.attrs.author)
            if (!vnode.state.author) vnode.state.not_found = true
            else if (vnode.state.author.style) {
                m.redraw()
                Authors.load_writing_style_compressed(vnode.attrs.author, writing_style => {
                    vnode.state.writing_style_lines = do_something_to_style(writing_style)
                    redraw()
                })
            }
        })

        Reviews.load(() => {
            Authors.load(() => {
                if (vnode.state.author) {
                    vnode.state.reviews = Reviews.list.filter(r => vnode.state.author.works.includes(r.code))
                }
                else {
                    console.error('No author')
                }
            })
        })

        fetch('/links_by_author/' + vnode.attrs.author)
            .then(json_result => json_result.json())
            .then(result => {
                if (result.length) {
                    vnode.state.has_physical = result
                    m.redraw()
                }
            })
    },

    view: function (vnode) {

        let author = vnode.state.author || {}

        if (vnode.state.not_found) {
            return [
                m('.page',

                    m('.p-10',
                        m('h1', '404 - Author ‘', vnode.attrs.author, '’ Not Found'),
                        m('a.my_link', { href: '/' }, 'Return Home'),
                    )
                )
            ]
        }

        return [

            m(breadcrumbs, {
                links: ['/authors'],
                title: author.pen_name
            }),

            m('.page.author_page',
                {
                    itemscope: true,
                    itemtype: 'https://schema.org/Person'
                },

                m('.max-700',

                    //PEN NAME
                    m('h1.default_h1',//author_title',
                        {
                            itemprop: author.full_name && author.full_name != author.pen_name ? undefined : 'name'
                        },
                        author.pen_name || m('.word_shimmer', '-')
                    ),

                    //FULL NAME
                    (author.full_name && author.full_name != author.pen_name) ?
                        m('h2.default_h2',//author_full_name',

                            {
                                itemprop: 'name'
                            },
                            author.full_name) : m('.default_h2'),


                    //INFO
                    m('.author_top_row',

                        m(author_profile_pic, { author: author }),
                        //((author.nationality || author.born || author.died)) 
                        (!vnode.state.author || (author.nationality || author.born || author.died))
                            ? m(
                                '.author_details',
                                m('',
                                    {
                                        itemprop: author.nationality ? 'nationality' : undefined,
                                        content: author.nationality ? author.nationality : undefined,
                                    },
                                    author.code ? ['Country: ', author.nationality || '???'] : m('.thin_word_shimmer', '-')),
                                m('',
                                    {
                                        itemprop: author.born?.year ? 'birthDate' : undefined,
                                        content: author.born?.year ? author.born?.year : undefined,
                                    },
                                    author.code ? ['Born: ', author.born?.year || '???'] : m('.thin_word_shimmer', '-')),
                                m('',
                                    {
                                        itemprop: author.died?.year ? 'deathDate' : undefined,
                                        content: author.died?.year ? author.died?.year : undefined,

                                    },
                                    author.code ? ['Died: ', author.died?.year || '???'] : m('.thin_word_shimmer', '-')),
                            ) : []
                    ),

                    m(filter_library_button, { author: author }),
                    m('.p-10'),

                    //Quote
                    // m('.p-10'),
                    // author?.quote ?
                    (!author.code || author.quote) ? m(quote_bar, {
                        loading: !author.code,
                        quote: {
                            quote: ('“' + author.quote + '”') + (author.quote_story_code ? (' * ' + author.quote_story_title) : ''),
                            code: author.quote_story_code
                        }
                    }) : [],
                    // [
                    // m('.p-10.top_quote', '“' + author.quote + '”',
                    //     author.quote_story_code ? [' - ', m('a.my_link', { href: '/onlinereader/' + author.quote_story_code }, author.quote_story_title)] : []
                    //     )]

                    // : [],

                    // m('.p-20'),

                    //Library Button

                    //Genres
                    m(genres_section, { author: author }),

                    //Writing Style
                    author.style ? (m(style_of_writing, { writing_style_lines: vnode.state.writing_style_lines })) : [],

                    author.bio ? m(bio, { author: author }) : [],

                    //Show all works if low
                    // (author.works && author.works.length <= MAX_BOOKS_TO_SHOW ?

                    // m(all_works, { author: author })
                    // []
                    // :
                    // ([

                    //Or show classics
                    (author.classics ?
                        m(classics, { author: author }) :

                        //Or nothing
                        []
                    ),
                    // ]
                    // ),


                    vnode.state.reviews && vnode.state.reviews.length ? m(reviews, { author: author, reviews: vnode.state.reviews }) : [],


                    author.relations ? m(relations, { author: author }) : [],

                    author.websites ? m(websites, { author: author }) : [],

                    vnode.state.has_physical ? m(print_links, { links: vnode.state.has_physical }) : [],

                    m('.p-10'),
                )
            )
        ]
    }
}

const author_profile_pic = {
    view: vnode => {
        return [

            m('.author_profile_pic',
                Authors.loaded ? m('img',
                    {
                        itemprop: vnode.attrs.author.profile_pic ? 'image' : undefined,
                        src: vnode.attrs.author ? (vnode.attrs.author.profile_pic ? (image_base + vnode.attrs.author.code + '.jpg') : blank) : '',
                        alt: vnode.attrs.author.profile_pic ? ('A picture of ' + vnode.attrs.author.pen_name) : 'A placeholder silhouette of a generic person',
                        style: (vnode.attrs.author.profile_pic && vnode.attrs.author.profile_pic !== true) ? ('object-position:' + vnode.attrs.author.profile_pic) : '',

                    }
                ) : []
            )
        ]
    }
}

const all_works = {
    view: vnode => {
        return [m('.p-10',

            m('h3.align_centre', 'Works in the Library'),

            m('.escape_restraint',
                m('.ptb-20',
                    m('.d_flex.flex_wrap.flex_gap.d_centre',

                        (vnode.attrs.author.works || [])
                            .map(work => [

                                m(story_block, { code: work })
                                // m(box, {
                                //     no_scene: true,
                                //     code: work,
                                //     show_title: true,
                                //     show_author: false,
                                // })
                            ])
                    )
                )
            )
        )]
    }
}

const classics = {
    view: vnode => {
        return [m('.p-10',

            m('h3.default_h3', 'Most Recognised For...'),
            // m('.escape_restraint',
            m('.pt-20',
                m('.d_flex.flex_wrap.flex_gap',
                    (vnode.attrs.author.classics || [])
                        .map(work =>
                            m(story_block, { code: work })

                            // m(box, {
                            //     no_scene: true,
                            //     code: work,
                            //     show_title: true,
                            //     show_author: false,
                            // })
                        )
                )
            )
        )]
    }
}

const filter_library_button = {
    view: vnode => {
        return [m('.p-10',

            // m('h2', 'Read Now'),
            // m('.p-5'),


            m('a.heavy_button.find_library_button',
                {
                    href: vnode.attrs.author.code ? ('/stories?author=' + vnode.attrs.author.code) : null,
                    // rel: 'nofollow'
                },
                // 'Filter the Library by ' + vnode.attrs.author.pen_name, ' (', vnode.attrs.author?.works?.length, ')'
                'Find in the Library',
                vnode.attrs.author.code ? [
                    ' (',
                    vnode.attrs.author?.works?.length,

                    vnode.attrs.author?.works?.length > 1 ? ' stories' : ' story',

                    // Authors.get_n_works(vnode.attrs.author),                
                    ')'] : '',

                m('img', {
                    src: image_base + 'books-180x180.png',
                    alt: 'A pixel image of differently coloured books.'
                })
            ),
        )
        ]
    }
}

const reviews = {
    view: vnode => {
        return [
            m('.p-10',

                m('h3.default_h3', 'Recommendations'),
                m('.d_flex.flex_wrap.flex_gap',
                    (vnode.attrs.reviews)
                        .map(review => [
                            m(review_box, { review_code: review.code })
                        ])
                )
            )
        ]
    }
}

const style_of_writing = {
    view: vnode => {
        return [m('.p-10',

            m('h3.default_h3', 'Style'),
            m('.style_of_writing_body_text',
                vnode.attrs.writing_style_lines ? vnode.attrs.writing_style_lines.map(line =>
                    m('p',
                        line.map(phrase =>
                            phrase.is_link ?
                                m('a', {
                                    href: phrase.text.split('|')[1],
                                    target: phrase.text.split('|')[1].includes('http') ? '_blank' : undefined,
                                    class: phrase.text.split('|')[1].includes('http') ? 'external_link' : 'my_link'


                                }, phrase.text.split('|')[0])
                                :
                                phrase.text
                        )
                    )
                )
                    : m('.text_shimmer',

                        [...Array(10)].map(() => [
                            m('.text_shimmer_line_cover')
                        ])
                    )
                // vnode.attrs.writing_style
            )
        )]
    }
}
const bio = {
    view: vnode => {
        return [m('.p-10',

            m('h3.default_h3',
                'Style'),
            m('.',

                vnode.attrs.author.bio)
        )
        ]
    }
}

const websites = {
    view: vnode => {

        return [
            m('.p-10',
                m('h3.default_h3', 'External Websites'),
                m('.easy_reading',

                    vnode.attrs.author.websites.map((website, i) => [
                        m('.',
                            m('a.external_link',
                                {
                                    href: website.link,
                                    target: '_blank'
                                },
                                website.title
                            ),
                        ),
                        // (i < vnode.attrs.author.websites.length - 1) ? m('br') : []
                    ])
                )
            )
        ]
    }
}

//vnode.state.has_physical
const print_links = {
    view: vnode => {

        return [
            m('.p-10',
                m('h3.default_h3', 'Buy in Print'),
                m('.affiliate_tiles',

                    vnode.attrs.links.map((link, i) =>
                        m(print_link, { link: link }))
                )
            )
        ]
    }
}

const print_link = {
    view: vnode => {
        let link = vnode.attrs.link
        return [
            m('a.affiliate_tile',
                {
                    href: link.link,
                    target: '_blank'
                },
                m('.affiliate_tile_section_1',
                    m('.affiliate_tile_title', link.title),
                    m('.affiliate_tile_store', link.store),
                ),
                m('.affiliate_tile_section_2',
                    m('.affiliate_tile_stories',
                        link.story_details
                            .slice(0, 4)
                            .map(story_info =>


                                m(empty_story_block,
                                    {
                                        story: story_info
                                    }))
                    )
                ),
                m('.affiliate_tile_section_3',
                    m('.affiliate_tile_tag', link.tag),

                ),
            )
        ]
    }
}

const relations = {
    view: vnode => {

        return [
            m('.p-10',
                m('h3.default_h3', 'Relations'),
                m('.easy_reading',

                    vnode.attrs.author.relations.map(relation => [
                        m('.',
                            m('span', relation.title, ': '),
                            m('span', relation.authors
                                .map((author, i) => [

                                    m('a', {
                                        class: author.link[0] == '/' ? 'my_link' : 'external_link',
                                        href: author.link,
                                        target: author.link[0] == '/' ? '' : '_blank'
                                    }, author.title),
                                    author.quote ? m('span', [' (', author.quote, ')']) : [],
                                    (i != relation.authors.length - 1) ? ', ' : []

                                ]
                                )
                            ),
                        ),
                    ])
                )
            )
        ]
    }
}

const genres_section = {
    view: vnode => {

        let author = vnode.attrs.author

        return [
            //Genres
            m('.p-10',
                m('h3.default_h3', 'Genres'),

                m('.d_flex.flex_wrap.flex_gap',
                    (author.genres ? (Authors.get_author_main_genres({ author: author })) : [false, false])
                        .map(genre => {
                            let genre_object = genre ? Genres.list.find(g => g.code == genre.toLowerCase()) : {}
                            return [

                                m('a.my_link.genre_section', {
                                    href: genre ? ('/collection/' + genre.toLowerCase()) : null,
                                    key: genre
                                },
                                    m('.genre_tile',
                                        genre ? m('img', {
                                            src: image_base + genre_object.image + '.jpg',

                                            style: `${Genres.get_style(genre_object.hue)}; ${genre_object.image_position ? ('object-position: ' + genre_object.image_position) : ''}`,

                                            alt: genre_object.image_alt
                                        }) : [],

                                        m('.image_shade', {
                                            style: genre ? ('background-color:' + genre_object.colour) : ''
                                        }),
                                        m('.border')

                                    ),
                                    genre ? capitalise_first(genre) : m('.word_shimmer', '-')
                                )
                            ]
                        })
                )
            ),
        ]
    }
}


const empty_story_block = {
    view: vnode => {
        let story = vnode.attrs.story
        return [

            m('.basic_story_tile',

                story ? m('img.box_cover_image', {
                    loading: 'lazy',
                    src: image_base + story?.image,
                    style: story.genres ? Genres.get_style(Genres.list.find(g => g.code == story?.genres[0]).hue) : '',
                    alt: story.image_alt
                }) : [],
                m('.image_shade', {
                    style: (story?.genres && Genres.list.find(g => g.code == story?.genres[0]) ?

                        (
                            ('background:' + 'linear-gradient(0deg, ' + Genres.list.find(g => g.code == story?.genres[0]).colour + ' 0 60%, black 100%)')
                        )
                        : undefined
                    )
                }),

                m('.basic_story_tile_border'),

            )
        ]
    }
}


export default Page