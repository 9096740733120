import m from 'mithril'
import quote_bar from '../components/quote_bar'

import Quotes from '../../models/Quotes'
import Genres from '../../models/Genres'
import story_block from '../components/story_block'
import small_review_box from '../components/small_review_box'

import { capitalise_first } from '../../../server/logic/formatter'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const Page = {
    oninit: vnode => {
        vnode.state.random_story = Quotes.quotes[Math.floor(Math.random() * Quotes.quotes.length)].code

    },
    view: function (vnode) {


        return [
            m('.about_page.page',

                m('.max-800',
                    m('h1.default_h1', 'Discover'),
                    m('h2.default_h2', 'Find all the best short stories of history in my collections.'),

                    m('.p-10'),

                    m(quote_bar),

                    m('.p-20',

                        m('.full_discover_section',
                            m('a.my_link',
                                {
                                    href: '/classics'
                                },
                                m('h3', 'The Classics')),
                            m('h4', 'History’s ten most iconic short stories.'),
                            m('.block_arrangement',
                                CLASSICS_DENORMALISED.slice(0, 4)
                                    .map(story => m(story_block, { story: story })),
                                m(more_icon,
                                    {
                                        href: '/classics'
                                    }
                                )
                            ),
                        ),
                    ),
                    // m('.p-10'),

                    m(quote_bar),

                    m('.p-20',

                        m('.full_discover_section',
                            m('a.my_link',
                                {
                                    href: '/choice'
                                },
                                m('h3', 'Librarian’s Picks')),
                            m('h4', 'My choice for the best short stories of the library.'),
                            m('.block_arrangement',
                                PICKS_DENORMALISED.slice(0, 4)
                                    .map(story => m(story_block, { story: story })),
                                m(more_icon,
                                    {
                                        href: '/choice'
                                    })

                            ),
                        ),
                    ),

                    m(quote_bar),

                    m('.p-20',


                        m('.full_discover_section',
                            m('a.my_link',
                                {
                                    href: '/genres'
                                },
                                m('h3', 'Best of Genre')),
                            m('h4', 'My top picks for twelve genres from poetry to war.'),

                            m('.general_discover_flex',
                                Genres.list.filter(g => ['sci-fi', 'horror', 'war'].includes(g.code))

                                    .map(g =>
                                        m(small_genre_box, { genre: g })
                                    ),
                                // m(small_genre_box, {genre: }),
                                // m(small_genre_box),
                                // m(small_genre_box),
                                m(more_icon, { href: '/genres' })
                            )
                        ),
                    ),

                    m(quote_bar),

                    m('.p-20',

                        m('.full_discover_section',
                            m('a.my_link',
                                {
                                    href: '/collections'
                                },
                                m('h3', 'Themed Collections')),
                            m('h4', 'Collections of short stories connected by theme.'),

                            m('.general_discover_flex',
                                collection_images.map(collection =>
                                    m(small_theme_box, { collection: collection }),
                                ),

                                m(more_icon,
                                    {
                                        href: '/collections'
                                    })

                            ),
                        ),


                    ),


                    m(quote_bar),

                    m('.p-20',

                        m('.full_discover_section',
                            m('a.my_link',
                                {
                                    href: '/series'
                                },
                                m('h3', 'Series')),
                            m('h4', 'Series of stories with recurring characters.'),

                            m('.general_discover_flex',
                                series_images.map(collection =>
                                    m(small_series_box, { collection: collection }),
                                ),

                                m(more_icon,
                                    {
                                        href: '/series'
                                    })

                            ),
                        ),


                    ),

                    m(quote_bar),

                    m('.p-20',

                        m('.full_discover_section',
                            m('a.my_link',
                                {
                                    href: '/reviews'
                                },
                                m('h3', 'Recommended')),
                            m('h4', 'Short reviews of the most unique stories.'),

                            m('.block_arrangement',
                                REVIEWS_DENORMALISED.slice(0, 4)
                                    .map(story => m(small_review_box, { story: story })),
                                m(more_icon, { href: '/reviews' })
                            ),
                        ),

                    ),

                    m(quote_bar),


                    m('.p-20',
                        m('.small_home_sections',
                            [
                                { title: 'Don’t have much time?', button_text: 'Shortest Stories', link: '/five-minute-stories' },
                                { title: 'Analysis Paralysis?', button_text: 'Read My Favourite', link: '/onlinereader/the-man-who-could-imitate-a-bee' },
                                { title: 'Feeling Lucky?', button_text: 'Random Story', link: '/onlinereader/' + vnode.state.random_story },
                            ].map(sec =>
                                m('a.small_home_section.true_link',
                                    {
                                        href: sec.link,
                                        rel: sec.rel
                                    },

                                    m('h3', sec.title),
                                    m('.heavy_button',
                                        sec.button_text)
                                )
                            )
                        )
                    )
                )
            )
        ]
    }
}



export default Page





const small_genre_box = {

    view: vnode => {

        let genre = vnode.attrs.genre

        return [

            m('a.small_genre_box.true_link',
                {
                    href: '/collection/' + genre.code
                },

                m('.small_genre_box_section_1',
                    [...Array(3)].map((_, i) => [
                        m('.small_genre_box_image',
                            m('img.', {
                                loading: 'lazy',
                                style: Genres.get_style(genre.hue),
                                src: (image_base + (genre.images ? genre.images[i].src : genre.image)) + '.jpg',
                                alt: (genre.images ? genre.images[i].alt : genre.image_alt),

                            }),
                            m('.image_shade', { style: 'background-color:' + genre.colour }),
                            m('.small_genre_box_image_border')
                        )
                    ])
                ),

                m('.small_genre_box_button.heavy_button.fake_link',
                    {
                        style: 'border-color: ' + genre.colour, // + ';color: ' + genre.colour,
                        //background: white; 
                    },
                    [
                        'Best of ',
                        m('b', capitalise_first(genre.code))
                    ]
                )


            )
        ]
    }
}

const small_theme_box = {
    view: vnode => {
        let collection = vnode.attrs.collection
        return [
            m('a.three_books_section.remove_default_link_styling.true_link',
                {
                    href: collection.subroute ? ('/collection/' + collection.subroute) : collection.route
                },
                m('.read_the_classics',
                    m('.align_centre.w_100',
                        m('.collection_link_header.centre', collection.title || m('.thin_word_shimmer', '-')),
                    )
                ),

                m('.collection_split'),

                m('img.drawn_image', {
                    src: image_base + 'collection-' + collection.subroute + '.jpg',
                    style: `filter: ${collection.image_filter}`,
                    alt: collection.image_alt,
                }),
            )
        ]
    }
}


const small_series_box = {
    view: vnode => {
        let collection = vnode.attrs.collection
        return [
            m('a.three_books_section.remove_default_link_styling.true_link',
                {
                    href: '/stories?search=' + collection.title
                },
                m('.read_the_classics',
                    m('.align_centre.w_100',
                        m('.collection_link_header.centre', collection.title || m('.thin_word_shimmer', '-')),
                    )
                ),

                m('.collection_split'),

                m('img.drawn_image', {
                    src: image_base + 'series_' + collection.title.toLowerCase().replace(/ /g, '_') + '.jpg',
                    alt: collection.image_alt,
                }),
            )
        ]
    }
}



const more_icon = {
    view: vnode => {
        return [
            m('a.more_icon_wrapper.my_link',
                {
                    href: vnode.attrs.href,
                    title: 'More'
                },
                m('.more_icon', m(''), m(''), m(''))
            )
        ]
    }
}



const CLASSICS_DENORMALISED = [
    { code: 'the-tell-tale-heart', title: 'The Tell-Tale Heart', author: 'Edgar Allan Poe', image: 'stare.jpg', alt: `A tense, staring eye of a man's face. Graphite filter`, genre: 'gothic' },
    { code: 'an-occurrence-at-owl-creek-bridge', title: 'An Occurrence at Owl Creek Bridge', author: 'Ambrose Bierce', image: 'creek.jpg', alt: 'A rocky creek in a forest. Graphite filter.', genre: 'war' },
    { code: 'the-lady-with-the-dog', title: 'The Lady with the Dog', author: 'Anton Chekhov', image: 'lovers.jpg', alt: 'A kissing man and woman, silhouetted in a forest. Graphite filter. Graphite filter.', genre: 'literary' },
    { code: 'the-most-dangerous-game', title: 'The Most Dangerous Game', author: 'Richard Connell', image: 'jungle.jpg', alt: 'A jungle of tall, twisting ferns. Graphite filter', genre: 'adventure' },

]
    .map(story => {
        story.image_alt = story.image
        story.genres = [story.genre]

        return story
    })

const PICKS_DENORMALISED = [
    { code: 'the-man-who-could-imitate-a-bee', title: 'The Man Who Could Imitate a Bee', author: 'Richard Connell', image: 'bee.jpg', alt: '"A bee on its side. Graphie Filter.', genre: 'quirky' },
    { code: 'the-outrage', title: 'The Outrage', author: 'Aleksandr I. Kuprin', image: 'gentleman.jpg', alt: 'A man with a suit and cane, tipping his hat. Graphite Filter.', genre: 'literary' },
    { code: 'clancy-of-the-overflow', title: 'Clancy of the Overflow', author: 'Banjo Paterson', image: 'drovers.jpg', alt: 'Three men with wide-brimmed hats riding horses in the outback.', genre: 'poetry' },
    { code: 'the-unexpected', title: 'The Unexpected', author: 'Jack London', image: 'shotgun.jpg', alt: 'A cracked shotgun with smoke rising from the empty barrels. Graphite filter.', genre: 'adventure' },

].map(story => {
    story.image_alt = story.image
    story.genres = [story.genre]

    return story
})

const REVIEWS_DENORMALISED = [
    { code: 'the-country-of-the-blind', title: 'The Country of the Blind', author: 'H. G. Wells', image: 'town.jpg', alt: 'A cliffside town. Graphite filter.', genre: 'adventure' },
    { code: 'the-shape-of-things', title: 'The Shape of Things', author: 'Ray Bradbury', image: 'pyramid.jpg', alt: 'A shiny pyramid on the floor. Graphite filter.', genre: 'sci-fi' },
    { code: 'the-mantle', title: 'The Mantle', author: 'Nikolai Gogol', image: 'hansom.jpg', alt: 'A man by a horse-drawn hansom in the snow. Graphite Filter.', genre: 'literary' },
    { code: 'the-masque-of-the-red-death', title: 'The Masque of the Red Death', author: 'Edgar Allan Poe', image: 'masque.jpg', alt: 'An expresionless carnival mask. Graphite filter.', genre: 'gothic' },

    //    { code: 'the-dream-of-a-ridiculous-man', title: 'The Dream of a Ridiculous Man', author: 'Fyodor Dostoevsky', image: 'earth.jpg', alt: 'Earth from space, focusing on Africa and covered in clouds. graphite filter.', genre: 'literary' },
    // { code: 'sasha', title: 'Sasha', author: 'Aleksandr I. Kuprin', image: 'fiddle.jpg', alt: 'A fiddle and bow. Graphite filter.', genre: 'tragedy' },

].map(story => {
    story.image_alt = story.image
    story.genres = [story.genre]

    return story
})

const series_images = [
    {
        title: 'Sherlock Holmes',
        image: 'detective.jpg',
        image_alt: 'A drawing of sherlock holmes and others over a dead body.',
    },
    {
        title: 'Conan the Barbarian',
        image: 'sword.jpg',
        image_alt: ' A drawing of Conan, with a roman style garb and wielding an axe defensively.'
    },
    {
        title: 'Thomas Carnacki',
        image: 'chalk.jpg',
        image_alt: 'A drawing of Thomas Carnacki upright in bed as a mysterious shadowy figure leans over him.'
    },
]

const collection_images = [

    {
        title: 'Living the Dream',
        subroute: 'dreams',
        image_filter: "contrast(2.5) brightness(2) saturate(0.4) hue-rotate(282deg)",
        image_alt: 'A pencil sketch of a sillhouetted man and woman kissing as biplanes shoot at them from the horizon over the sea.',
    },
    {
        title: 'A Short Romance',
        subroute: 'romance',
        image_alt: 'A pencil sketch of a decorated comb and a cut lock of hair.',
        image_filter: 'contrast(1.5) invert(1) hue-rotate(120deg) saturate(1.5)',

    },
    {
        title: 'Science Gone Too Far',
        subroute: 'too-far',
        image_alt: 'A  pencil sketch off a glass rotated six times in a loop and pouring into each other.',
        image_filter: 'invert(1) brightness(1.5) hue-rotate(246deg)',

    },

]