
import m from 'mithril'
import gradient_section from '../components/gradient_section'
import quote_bar from '../components/quote_bar'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const Page = {

    view: function (vnode) {

        return [

            m('.page.how_page',
                m(gradient_section,

                    m('.max-700',
                        m("h1.default_h1",
                            "About the Stories"
                        ),
                        m("h2.default_h2",
                            "Uncopyright and Understanding the works."
                        ),
                        m('.p-10'),
                    )),
                m('.max-700',

                    m(quote_bar),

                    m('.p-20',
                        m('h3.default_h3', 'Uncopyright'),

                        m('h4.default_h4', 'The Public Domain'),
                        m('p', 'All short stories on the Library of Short Stories are, to the best of my knowledge, in the Australian public domain. If you find any works for which you believe this isn’t the case, please ', m('a.external_link', { href: 'mailto:eclauthor@gmail.com' }, 'contact me'), ' and I will remove it.'),
                        m('p', 'If you live outside of Australia, please check your own laws with regards to copyright before reading or downloading any of the works. ', m('a.external_link', { href: 'https://en.wikipedia.org/wiki/List_of_countries%27_copyright_lengths', target: "_blank" }, 'Table of copyright durations by country.')),
                        m('p', `The website itself and the reviews are under copyright.`),
                        m(magazines_display),
                        m('h4.default_h4', 'Distribution'),
                        m('p', 'By the nature of their circumstance, you can read, download and distribute the short stories in any way you like (Provided that the work is in your own country’s public domain). From the online reader, I have provided links to share the story to social media. Each story also has a downloadable PDF and EPUB. Anyone is allowed and encouraged to link to individual stories, reviews or directly to the website itself. The Library of Short Stories will indefinitely remain a mobile-friendly and accessible source for short stories.'),
                    ),

                    m(quote_bar),

                    m('.p-20',
                        m("h3.default_h3",
                            "The Quirks of Classic Writings"
                        ),
                        m('h4.default_h4', 'Dashes for Names (T---)'),
                        m('p', 'Some words in works such as ', m('a.my_link', { href: '/onlinereader/the-lady-with-the-dog' }, 'The Lady with the Dog'), ' appear to redact words, particular proper names of people and towns. E.g. ‘s-------’, ‘18--’. This was a common literary device in the 18th and 19th century for a few simple reasons. Perhaps the author didn’t want to make up a name as it was seen as unprofessional, perhaps they didn’t want to be seen as slandering a particular place or person, wanted the story to be adaptable to the reader, wanted to create a dramatic sense that it was a real story and the character’s identities needed to be protected, etc. etc.'),
                        m('p', 'Not to be confused with me deliberately redacting H.G. Wells’s and H.P. Lovecraft’s ', m('i', 'very liberal'), ' use of racial slurs.'),

                        m('h4.default_h4', 'Preludes, poems and quotes'),
                        m('p', 'Many of the pieces seem to have a poem, quote or some other kind of prelude at the beginning of the work. They are all an intentional part of the story itself by the author, it was very fashionable to begin stories this way. Many have a quote from a famous poet that inspired their story, many have a description to add to the mystery of the story itself. e.g. ', m('i', 'I found this story inside the journal within the clutches of a dead skeleton in an abandoned library. Readers beware!'), ' '),
                        m('p', 'In ', m('a.my_link', { href: '/onlinereader/the-outrage' }, 'The Outrage'), ', Aleksandr Kuprin begins the story with calling it ‘A True Story’. Rest assured that the tale of a pickpocket convincing a courtroom that thieves are a benefit to society is not actually a true story.'),
                        m('h4.default_h4', 'Racism'),
                        m('p', 'Many of the authors in the Library of The Short Story, being from the 19th and 20th centuries, have outdated and downright racist attitudes. You may be reading an adventurous detective story about a missing king, or even a voyage to 22nd century Mars only to be smacked in the face with mention of  ‘The superior western intellect’ or an insulting caricature or stereotype. Some non-exhaustive content warnings have been added to the worst of the lot. But aside from minimal censoring of slurs, the works have been left as is for the sake of accuracy.'),                    )
                )
            )
        ]
    }
}

const magazines_display = {
    view: vnode => {
        return [
            m('.magazines',
                [
                    { code: 'a-friend-of-napoleon', alt: 'A magazine page titled "A FRIEND OF NAPOLEON" with an image of a man dusting wax figures' },
                    { code: 'the-chain-of-aforgomon', alt: 'A magazine page titled "The Chain of Aforgomon"' },
                    { code: 'the-shape-of-things', alt: 'A magazine page titled "The Shape of Things"' },
                    { code: 'the-ship-that-turned-aside', alt: 'A magazine page titled "The Ship that Turned Aside"' },
                    { code: 'the-silver-key', alt: 'A magazine page titled "The Silver Key"' },
                ].map((mag, i) =>
                    m('.magazine',
                        {
                            class: (i > 2 ? 'd_computer' : '')
                        },
                        m('img', {
                            src: image_base + mag.code + '-cover' + '.jpg',
                            alt: mag.alt,
                        }),
                        m('.border')
                    ),
                )
            ),

        ]
    }
}



export default Page