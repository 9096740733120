import m from 'mithril'
import Collections from '../../models/Collections'
import Genres from '../../models/Genres'
import Stories from '../../models/Stories'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const Page = {

    oninit: vnode => {
        Collections.load()
    },

    view: function (vnode) {

        return [
            m('.page.discovery_page',
                // m('.max-800',
                m('h1.default_h1', 'Short Story Theme Collections'),
                m('h2.default_h2',
                    'Themed collections of short stories to get you started. To see the best of each genre, check out the ', m('a.my_link', { href: '/genres' }, 'Genres'), ' page.'
                ),
                m('.p-20',


                    m('.d_flex.d_centre.flex_wrap.flex_gap_extra_large',

                        //  COLLECTIONS.concat(
                        (Collections.loaded ? Collections.list :
                            (
                                [...Array(6)]
                                    .map(_ => ({ skeleton: true }))
                            )
                        )
                            .filter(c => !c.is_genre)
                            // )

                            .map((collection) => [
                                m(collection_link, { collection: collection, key: collection.code })
                            ])
                    )
                ),
                m('.p-10'),
            )
        ]
    }
}

const collection_link = {
    view: vnode => {
        let collection = vnode.attrs.collection
        let colour_1
        let colour_2
        let colour_3
        if (collection.stories) {

            if (!vnode.state.story_1) {
                Stories.fetch_story_metadata(collection.stories[0].story, story => {
                    vnode.state.story_1 = story
                    m.redraw()
                }, true)
                Stories.fetch_story_metadata(collection.stories[1].story, story => {
                    vnode.state.story_2 = story
                    m.redraw()
                }, true)
                Stories.fetch_story_metadata(collection.stories[2].story, story => {
                    vnode.state.story_3 = story
                    m.redraw()
                }, true)
            }

            let genre_1 = vnode.state.story_1 ? vnode.state.story_1.genres[0] : 'gothic'
            let genre_2 = vnode.state.story_2 ? vnode.state.story_2.genres[0] : 'gothic'
            let genre_3 = vnode.state.story_3 ? vnode.state.story_3.genres[0] : 'gothic'

            colour_1 = Genres.get_faint_colour(genre_1)
            colour_2 = Genres.get_faint_colour(genre_2)
            colour_3 = Genres.get_faint_colour(genre_3)

        }

        let section_background_style = `background: linear-gradient(124deg, transparent, ${colour_1},  ${colour_2}, ${colour_3});`

        return [
            m('a.three_books_section.remove_default_link_styling.true_link',
                {
                    // class: collection.subroute ? '' : 'main_collection_link',
                    href: collection.subroute ? ('/collection/' + collection.subroute) : collection.route,
                    style: colour_1 ? section_background_style : ''
                },
                m('.read_the_classics',
                    m('.align_centre.w_100',
                        m('.collection_link_header.centre', collection.title || m('.thin_word_shimmer', '-')),
                        m('.collection_link_sub_header', collection.header_2 || m('.thin_word_shimmer', '-')),
                    )
                ),

                m('.collection_split'),


                m('img.drawn_image', {
                    src: !collection.skeleton ? (image_base + 'collection-' + collection.code + '.jpg') : null,
                    style: `filter: ${collection.image_filter}`,
                    alt: collection.image_alt,
                    title: collection.image_title
                }),

                // m('.three_books_wrapper',

                //     m('.three_books.w_initial',

                //         (collection.stories ||
                //             [{ skeleton: true, genres: ['gothic'] }, { skeleton: true, genres: ['gothic'] }, { skeleton: true, genres: ['gothic'] }])

                //             .slice(0, 3)

                //             .map(story => [
                //                 m(collection_story, { code: story.story }),

                //             ]),
                //     )
                // ),

                m('.collection_link_read', 'Collection')
            )
        ]
    }
}

const COLLECTIONS = [
    {
        title: 'The Classics',
        route: '/classics',
        header_2: 'The most recognisable short stories of history.',
        stories: [
            {
                story: 'the-tell-tale-heart',
            },
            {
                story: 'the-most-dangerous-game',
            },
            {
                story: 'a-scandal-in-bohemia',
            },
        ]
    },
    {
        title: 'Librarian’s Choice',
        route: '/choice',
        header_2: 'The Library’s choice for must-read stories.',
        stories: [{
            story: 'the-man-who-could-imitate-a-bee',
        },
        {
            story: 'the-outrage',
        },
        {
            story: 'clancy-of-the-overflow',
        },
        ]
    },
    {
        title: '5 Minute Stories',
        route: '/five-minute-stories',
        header_2: "The Shortest of the short - Short stories between 1 and 10 minutes long.",
        stories: [
            {
                story: 'sredni-vashtar',
            },
            {
                story: 'the-terrible-old-man',
            },
            {
                story: 'luck',
            },
        ]
    }
]

const collection_story = {
    oninit: vnode => {
        Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
            vnode.state.story = story
            m.redraw()
        })
    },

    view: vnode => {

        let story = vnode.state.story

        let genre = story ? story.genres[0] : 'gothic'
        return [
            m('.collection_story',

                story ? m('img.box_cover_image', {
                    loading: 'lazy',
                    src: image_base + story?.image,
                    style: story ? Genres.get_style(Genres.list.find(g => g.code == genre).hue) : '',
                    alt: story?.image_alt
                }) : [],
                m('.image_shade', {
                    style:

                        'background:' +
                        'linear-gradient(0deg, ' +
                        Genres.list.find(g => g.code == genre).colour +
                        ' 0 60%, black 100%)'

                }),

                m('.basic_story_tile_border'),

            )
        ]
    }
}

export default Page