import m from 'mithril'
import Genres from '../../models/Genres'
import Stories from '../../models/Stories'
import { search_icon } from './icons'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'
const base_image_url_2 = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/covers/small/'

const small_review_box = {
    oninit: vnode => {
        if (vnode.attrs.story) vnode.state.story = vnode.attrs.story
        else {
            Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
                vnode.state.story = story
                m.redraw()
            })
        }
    },

    view: vnode => {

        let story = vnode.state.story || vnode.attrs.story

        let image = (image_base + story?.image)
        let image_alt = story?.image_alt
        let style = story ? Genres.get_style(Genres.list.find(g => g.code == story?.genres[0]).hue) : ''

        if (story?.unique_cover) {
            image = (base_image_url_2 + story.code.replace(/-/g, '_') + '.jpg')
            image_alt = story.unique_cover
            style = ''
            if (story.cover_position) {
                style += ` ;object-position:${story.cover_position}`
            }
        }

        return [
            m('a.small_review_box',
                {
                    href: story ? ('/review/' + story.code) : undefined
                },

                m('.small_review_box_section_1',
                    m(search_icon),

                    story ? m('img.box_cover_image', {
                        loading: 'lazy',
                        src: image,
                        style: style,
                        alt: image_alt
                    }) : [],
                    !story?.unique_cover ? m('.image_shade', {
                        style: (story?.genres && Genres.list.find(g => g.code == story?.genres[0]) ?

                            (
                                ('background:' + 'linear-gradient(0deg, ' + Genres.list.find(g => g.code == story?.genres[0]).colour + ' 0 60%, black 100%)')
                            )
                            : undefined
                        )
                    }) : [],


                    story ? [
                        // m('.basic_story_tile_title',
                        //     story.title,

                        //     m('.basic_story_tile_author', story.author) ,
                        // )
                    ]
                        : [],

                ),
                // m('.separate_top'),
                m('.small_review_box_section_2',
                    m('.small_review_box_line', 'Why You Should Read...'),
                    m('.small_review_box_title', story ? story.title : [m('.word_shimmer.matching_half_shimmer', '-')]),

                ),
                m('.basic_border'),

            )
        ]
    }
}

export default small_review_box