import m from 'mithril'
import { parse_text_to_lines } from '../../logic/Formatter'
const base_image_url = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const Text_Italiciser = {

    view: function (vnode) {

        let lines = parse_text_to_lines(vnode.attrs.text)

        return [

            lines.map(line =>
                m('p',
                    line.map(phrase =>
                        phrase.image ? [
                            m('img', {
                                src: base_image_url + phrase.image.src,
                                alt: phrase.image.alt
                            })
                        ] :
                            phrase.italic ?
                                m('i', phrase.text)
                                :
                                phrase.text
                    )
                )
            )
        ]
    }
}


export default Text_Italiciser