import m from 'mithril'
import gradient_section from '../components/gradient_section'
import quote_bar from '../components/quote_bar'

const Page = {

    view: function (vnode) {

        return [
            m('.furtherreading_page.page',
                m(gradient_section,

                    m('.max-700',
                        m('h1.default_h1', 'Further Reading Resources'),
                        m('h2.default_h2',
                            //     'Links to other literature websites'),

                            // m('p.general_reading_font',
                            'Project Gutenberg, Internet Archive, and other online libraries. This page will help you find free literature from around the internet.'),

                        m('.p-10'),
                    )),
                m('.max-700',

                    m('.general_reading_font',

                        m(quote_bar),
                        m('.p-20',
                            m('h3.default_h3', 'Project Gutenberg'),
                            m('p.', 'If it exists anywhere, it’s probably on Project Gutenberg, the largest database of public domain literature. The website has been around for fifty years, amassing works through volunteers.'),
                            m('p.', 'There are, however, limited features for discovering anything you aren’t specifically looking for, and the formatting is very outdated.'),
                            m('p',
                                m('.', m('a.external_link', { href: 'https://www.gutenberg.org/', target: '_blank' }, 'Project Gutenberg (U.S. Based)')),
                                m('.', m('a.external_link', { href: 'https://gutenberg.net.au/', target: '_blank' }, 'Project Gutenberg Australia')),
                                m('.', m('a.external_link', { href: 'https://gutenberg.ca/index.html', target: '_blank' }, 'Project Gutenberg Canada (They are not happy about new copyright extensions)')),
                            )
                        ),
                        m(quote_bar),
                        m('.p-20',
                            m('h3.default_h3', 'Internet Archive'),

                            m('p', 'For the primary sources themselves, you can find scans of books, magazines and newspapers from throughout history at the ',
                                m('a.external_link', { href: 'https://archive.org/', target: '_blank' }, 'Internet Archive'), '.'
                            ),

                            m('p', 'You can see where Arthur Conan Doyle rose to fame in ',
                                m('a.external_link', { href: 'https://archive.org/details/TheStrandMagazineAnIllustratedMonthly/TheStrandMagazine1891aVol.IJan-jun/', target: '_blank' }, 'The Strand'),
                                ', where Lovecraft, Clark Ashton Smith and Robert E. Howard scraped pennies for their tales in ',
                                m('a.external_link', { href: 'https://archive.org/details/burtonsgentleman1839burt/page/n5/mode/2up', target: '_blank' }, 'Weird Tales'),
                                ', and where Poe created history in ',
                                m('a.external_link', { href: 'https://archive.org/details/burtonsgentleman1839burt/page/n5/mode/2up', target: '_blank' }, 'Gentleman’s Magazine'),
                                '.'
                            ),
                        ),

                        m(quote_bar),
                        m('.p-20',
                            m('h3.default_h3', 'Other Free, Online Libraries'),

                            m('p',
                                m('.', 'More Classic Short Stories - ',
                                    m('a.external_link', { href: 'https://www.classicshorts.com/', target: '_blank' }, 'classicshorts.com'),
                                    ' - ',
                                    m('a.external_link', { href: 'https://www.eastoftheweb.com/', target: '_blank' }, 'East of the Web'),
                                    ' - ',
                                    m('a.external_link', { href: 'https://americanliterature.com/', target: '_blank' }, 'American Literature'),
                                    ' - ',
                                    m('a.external_link', { href: 'https://writingatlas.com/', target: '_blank' }, 'Writing Atlas')


                                ),
                                m('.', 'Novels - ',
                                    m('a.external_link', { href: 'https://openlibrary.org//', target: '_blank' }, 'Open Library'),
                                    ' - ',
                                    m('a.external_link', { href: 'https://www.goodreads.com/shelf/show/free-online', target: '_blank' }, 'Goodreads'),
                                    ' - ',
                                    m('a.external_link', { href: 'https://standardebooks.org/', target: '_blank' }, 'Standard Ebooks'),
                                    ' - ',
                                    m('a.external_link', { href: 'https://www.fadedpage.com/', target: '_blank' }, 'Faded Page'),



                                ),
                                m('.', 'Poetry - ', m('a.external_link', { href: 'https://www.public-domain-poetry.com/', target: '_blank' }, 'Public Domain Poetry')),
                                m('.', 'More Scans - ', m('a.external_link', { href: 'https://www.pulpmags.org/index.htm', target: '_blank' }, 'The Pulp Magazines Project')),


                                
                            ),

                        ),

                        m(quote_bar),
                        m('.p-20',
                            m('h3.default_h3', 'Copyrighted Classics'),
                            m('p.', 'Here are a few iconic short stories still under copyright. Some links may stop working if it turns out that the hosts did not have proper permission to display the stories.'),
                            m('p',

                                m('.', 'Daniel Keyes - ', m('a.external_link', { href: 'https://www.alcaweb.org/arch.php/resource/view/172077', target: '_blank' }, 'Flowers for Algernon')),
                                m('.', 'Flannery O’Connor - ', m('a.external_link', { href: 'https://repositorio.ufsc.br/bitstream/handle/123456789/160332/A%20good%20man%20is%20hard%20to%20find%20-%20Flannery%20O%27Connor.pdf', target: '_blank' }, 'A Good Man is Hard to Find')),
                                m('.', 'Shirley Jackson - ', m('a.external_link', { href: 'https://www.newyorker.com/magazine/1948/06/26/the-lottery', target: '_blank' }, 'The Lottery')),
                                m('.', 'Raymond Carver - ', m('a.external_link', { href: 'https://tnsatlanta.org/wp-content/uploads/What-We-Talk-About-When-We-Talk-About-Love-Carver.pdf', target: '_blank' }, 'What We Talk About When We Talk About Love')),
                                m('.', 'Jorge Louis Borges - ', m('a.external_link', { href: 'https://sites.evergreen.edu/politicalshakespeares/wp-content/uploads/sites/226/2015/12/Borges-The-Library-of-Babel.pdf', target: '_blank' }, 'The Library of Babel')),
                                m('.', 'Isaac Asimov - ', m('a.external_link', { href: 'https://users.ece.cmu.edu/~gamvrosi/thelastq.html', target: '_blank' }, 'The Last Question')),
                                
                            )
                        ),

                        m(quote_bar),
                        m('.p-20',

                            m('h3.default_h3', 'Complete Anthologies'),
                            // m('p', 'Some authors have a passionate community around them, allowing them to have a dedicated website containing their full works, extra content such as artwork, letters and essays by the author, and discussion forums websites.'),

                            m('p',

                                m('.', 'Sherlock Holmes (Arthur Conan Doyle) - ',
                                    m('a.external_link', {
                                        href: 'https://sherlock-holm.es/',
                                        target: '_blank'
                                    }, 'PDF, EPUB or online - sherlock-holm.es')),

                                m('.', 'H. P. Lovecraft - ',
                                    m('a.external_link', {
                                        href: 'https://arkhamarchivist.com/ebook/The%20Complete%20Works%20of%20H.P.%20Lovecraft.pdf',
                                        target: '_blank'
                                    }, 'PDF - Arkham Archivist')),

                                m('.', 'Edgar Allan Poe - ',
                                    m('a.external_link', {
                                        href: 'https://onemorelibrary.com/index.php/en/books/major-collections/edgar-allan-poe-collection-320',
                                        target: '_blank'
                                    }, 'EPUB - onemorelibrary.com')),

                                m('.', 'Franz Kafka - ',
                                    // m('a.external_link', {
                                    //     href: 'https://www.vanderbilt.edu/olli/class-materials/Franz_Kafka.pdf',
                                    //     target: '_blank'
                                    // }, 'PDF - Vanderbilt.edu'),
                                    // ' - ',
                                    m('a.external_link', {
                                        href: 'https://www.kafka-online.info/',
                                        target: '_blank'
                                    },
                                        'Online - Franz Kafka Online'
                                    )
                                ),
                                m('.', 'Clark Ashton Smith - ',
                                    m('a.external_link', {
                                        href: 'http://www.eldritchdark.com/',
                                        target: '_blank'
                                    },
                                        'Online - Eldritch Dark'
                                    )
                                ),
                            ),
                        ),
                        m(quote_bar),
                        m('.p-20',

                            m('h3.default_h3', 'Modern Short Stories'),
                            m('p',


                                m('.',
                                    `Online Magazine (Sci-Fi and Fantasy) *Free Online* - `,
                                    m('a.external_link', {
                                        href: 'https://clarkesworldmagazine.com/',
                                        target: '_blank'
                                    }, 'Clarkesworld')
                                ),
                                m('.',
                                    `Online Magazine (Speculative Fiction) - `,
                                    m('a.external_link', {
                                        href: 'https://ethereamagazine.com/',
                                        target: '_blank'
                                    }, 'Etherea Magazine')
                                ),
                                m('.',
                                    `Online Magazine (Speculative Fiction) - `,
                                    m('a.external_link', {
                                        href: 'https://aurealis.com.au/',
                                        target: '_blank'
                                    }, 'Aurealis')
                                ),
                                m('.',
                                    `Print Magazine (Sci-Fi) - `,
                                    m('a.external_link', {
                                        href: 'https://www.analogsf.com/',
                                        target: '_blank'
                                    }, 'Analog: Science Fiction and Fact')
                                ),

                                m("br"),


                                m('.',
                                    'Weekly Online Short Story Competition - ',
                                    m('a.external_link',
                                        { href: 'https://blog.reedsy.com/short-stories/', target: '_blank' },
                                        'reedsy.com')
                                ),
                                m('.',
                                    'Monthy Online Short Story Competition (Australia only) - ',
                                    m('a.external_link',
                                        { href: 'https://www.writerscentre.com.au/furious-fiction/', target: '_blank' },
                                        'Australian Writer’s Centre - Furious Fiction')
                                ),


                            ),
                        ),


                        m(quote_bar),
                        m('.p-20',

                            m('h3.default_h3', 'Modern Short Story Collections'),
                            m('p',
                                m('.',
                                    m('a.sponsor_link', {
                                        href: 'https://amzn.to/49OIXrL',
                                        target: '_blank',
                                        rel: 'sponsored'
                                    }, 'Old Babes in the Wood'),
                                    ' by Margaret Atwood (2023)'
                                ),
                                m('.',
                                    m('a.sponsor_link', {
                                        href: 'https://amzn.to/4dbHwGH',
                                        target: '_blank',
                                        rel: 'sponsored'

                                    }, 'You Like it Darker'),
                                    ' by Stephen King (2024)'
                                ),
                            ),
                        ),


                        m(quote_bar),
                        m('.p-20',

                            m('h3.default_h3', 'From the Creator'),
                            m('p',

                                m('.',
                                    `The Creator’s Original Short Stories - `,
                                    m('a.external_link', {
                                        href: 'https://www.astoryperweek.com',
                                        target: '_blank'
                                    }, 'www.astoryperweek.com')
                                ),

                                // m('.',
                                //     `The Creator’s Portfolio - `,
                                //     m('a.external_link', {
                                //         href: 'https://www.evanclewis.com',
                                //         target: '_blank'
                                //     }, 'www.evanclewis.com')
                                // )
                            )
                        )
                    )
                )
            )
        ]
    }
}


export default Page


