import m from 'mithril'
// import skip_button from '../components/skip_button'

import story_display from '../components/story_display'
import quote_bar from '../components/quote_bar'

const Page = {
    view: function (vnode) {

        return [
            m('.page.collection_page.five-minute-page',
                {
                    itemscope: true,
                    itemtype: 'https://schema.org/Guide'
                },
                m('h1.default_h1', '15 Minute Stories'),
                m('h2.default_h2', ' Quality stories between 10 and 20 minutes.'),

                m(quote_bar, { quote: { quote: '“For thence stretched mystic avenues which seemed to promise escape from life”. * The Silver Key', code: 'the-silver-key' } }),


                // m(skip_button),

                m('.collection_padding',

                    m(opening_text),

                    m('.d_flex.flex_wrap.flex_gap_large.d_centre.d_centre',
                        TITLES.map((choice, i) => m(story_display, { choice: choice, show_time: true, id: i == 0 ? 'first_story' : '' }))
                    )
                )
            )
        ]
    }
}
export default Page

const opening_text = {
    view: vnode => {
        return [
            m('.opening_reading',

                m('p', `Stories that perfectly fit into a train ride or waiting line. Make a momentary adventure into the city of `,
                    m('a.my_link', { href: '/onlinereader/celephais' }, `Celephaïs`),
                    `, cheer yourself up with the short comedy of `,
                    m('a.my_link', { href: '/onlinereader/the-man-who-could-imitate-a-bee' }, `The Man Who Could Imitate a Bee`),
                    `, or take the chance to examine society with `,
                    m('a.my_link', { href: '/onlinereader/black-fog' }, `Black Fog`),
                    `.`
                ),

                // m('p.',
                //     `You won’t be able to read anything but poetry and `,
                //     m('a.my_link', { href: '/author/aesop' }, `Aesop`),
                //     `'s fables in under five minutes. But past that you can start to squeeze in stories like the Lovecraft Horror `,
                //     m('a.my_link', { href: '/onlinereader/the-terrible-old-man' }, `The Terrible Old Man`),
                //     `, or the Chekhov’s silly comedy `,
                //     m('a.my_link', { href: '/onlinereader/the-orator' }, `The Orator`),
                //     `. Scroll further down for longer stories or head over to the `,
                //     m('a.my_link', { href: '/stories' }, `Library`),
                //     ` to sort and filter by word length.`
                // ),
            ),
        ]
    }
}


const TITLES =
    [
        { story: 'my-uncle-jules' },
        { story: 'celephais' },
        { story: '2br02b' },
        { story: 'a-bottomless-grave' },
        { story: 'cain' },
        { story: 'cool-air' },
        { story: 'in-the-vault' },
        { story: 'confessions-of-a-humorist' },
        { story: 'the-nightingale' },
        { story: 'autumn-flowers' },
        { story: 'the-big-trip-up-yonder' },
        { story: 'the-demon-of-the-flower' },
        { story: 'double-cosmos' },
        { story: 'the-man-who-could-imitate-a-bee' },
        { story: 'the-argonauts-of-the-air' },
        { story: 'asleep-in-armageddon' },
        { story: 'beyond-the-wall-of-sleep' },
        { story: 'the-gorgon' },
        { story: 'black-fog' },
        { story: 'the-drug' },
        { story: 'the-silver-key' },
        { story: 'aepyornis-island' }

    ]