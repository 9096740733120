import { redraw } from 'mithril'

const Reviews = {

    list: [],

    load: (cb = () => { }, hard_load = false) => {

        if (!hard_load && Reviews.list && Reviews.list.length > 0) return cb()

        if (Reviews.loading_list) {
            Reviews.callback_queue = Reviews.callback_queue || []
            Reviews.callback_queue.push(cb)
            return
        }
        else {

            Reviews.loading_list = true
            //LOAD

            fetch('/reviewslist')
                .then(json_result => json_result.json())

                .then(reviews => {

                    Reviews.loading_list = false
                    Reviews.list = reviews

                    cb()
                    if (Reviews.callback_queue) {
                        Reviews.callback_queue.forEach(waiting_callback => {
                            waiting_callback()
                        })
                        Reviews.callback_queue = []
                    }

                    redraw()
                }).catch(err => {
                    console.error(err)
                })
        }
    },

    fetchtxt: (code, cb, error_cb = () => { }) => {

        let url = "/reviewtext/" + code + '.txt'

        fetch(url)
        .then(json_result => json_result.json())
            
            .then((result) => {

            cb(result.text)
        }).catch(err => {
            console.error(err)
            error_cb()
        })
    },

    fetchtxt_gzip: (code, cb = () => { }, error_cb = () => { }) => {
        let url = "/reviewgzip/" + code + '.txt'


        fetch(url)
            .then(result => {
                if (result.ok) {
                    result.text()
                        .then((result) => {
                            if (result) {
                                cb(result)
                            }
                            else {
                                console.error('No Result 28359')
                                Reviews.fetchtxt(code, cb, error_cb)

                            }
                        })
                }
                else {
                    console.error('Not OK', result)
                    Reviews.fetchtxt(code, cb, error_cb)
                }
            })
    },
}

export default Reviews