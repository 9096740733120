
import m from 'mithril'

import Genres from '../../models/Genres'
import Stories from '../../models/Stories'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'
const base_image_url_2 = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/covers/small/'


const story_block = {
    oninit: vnode => {
        if (vnode.attrs.story) vnode.state.story = vnode.attrs.story
        else {
            Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
                vnode.state.story = story
                m.redraw()
            })
        }
    },

    onupdate: vnode => {
        if (vnode.attrs.code && vnode.state.story && vnode.attrs.code != vnode.state.story.code && !vnode.state.loading) {

            vnode.state.loading = true

            Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
                vnode.state.story = story
                vnode.state.loading = false

                m.redraw()
            })
        }

    },

    view: vnode => {
        let story = vnode.state.story || vnode.attrs.story

        return [
            m('a.basic_story_tile',
                {
                    href: story ? ('/onlinereader/' + story.code) : undefined
                },
                story ? m(cover_image, { story: story }) : [],
                m('.image_shade', {
                    style: (story?.genres && Genres.list.find(g => g.code == story?.genres[0]) ?

                        (
                            ('background:' + 'linear-gradient(0deg, ' + Genres.list.find(g => g.code == story?.genres[0]).colour + ' 0 60%, black 100%)')
                        )
                        : undefined
                    )
                }),

                m('.basic_story_tile_border'),

                story ? [
                    m('.basic_story_tile_title',
                        story.title,

                        m('.basic_story_tile_author', story.author) ,
                    )
                ]
                    : []
            )
        ]
    }
}

const cover_image = {

    view: vnode => {
        let story = vnode.attrs.story
        if (!story) return

        let image = (image_base + story.image)
        if (story.unique_cover) { image = (base_image_url_2 + story.code.replace(/-/g, '_') + '.jpg') }

        let style = (story.genres ? Genres.get_style(Genres.list.find(g => g.code == story?.genres[0]).hue) : '')
        if (story.cover_position) { 
            style += ` ;object-position:${story.cover_position}`
        }
        return m('img.box_cover_image', {
            loading: 'lazy',
            alt: story.image_alt,
            src: image,

            style: style
        })
    }
}



export default story_block