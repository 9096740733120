import { redraw } from 'mithril'

const Collections = {

    list: [],
    loaded: false,

    load: (cb = () => { }, hard_load = false) => {

        if (!hard_load && Collections.list && Collections.list.length > 0) return cb()

        if (Collections.loading_list) {
            Collections.callback_queue = Collections.callback_queue || []
            Collections.callback_queue.push(cb)
            return
        }
        else {

            Collections.loading_list = true
            //LOAD

            fetch('/collectionslist')
                .then(json_result => json_result.json())

                .then(collections => {

                    Collections.loading_list = false
                    Collections.list = collections

                    Collections.loaded = true

                    cb()
                    if (Collections.callback_queue) {
                        Collections.callback_queue.forEach(waiting_callback => {
                            waiting_callback()
                        })
                        Collections.callback_queue = []
                    }

                    redraw()
                }).catch(err => {
                    console.error(err)
                })
        }
    },

    load_collection_text_gzip: (code, cb = () => { }) => {
        let url = "/collectiongzip/" + code + '.txt'

        fetch(url)
            .then(result => {
                if (result.ok) {
                    result.text()
                        .then((result) => {
                            if (result) {
                                cb(result)
                            }
                            else {
                                Collections.fetchtxt(code, cb)
                            }
                        })
                }
                else {
                    Collections.fetchtxt(code, cb)
                }
            })
    },

    fetchtxt: (code, cb, error_cb = () => { }) => {

        let url = "/collectiontext/" + code + '.txt'

        fetch(url)
            .then(json_result => json_result.json())
            .then((result) => {

                cb(result.text)
            }).catch(err => {
                console.error(err)
                error_cb()
            })
    }
}

export default Collections