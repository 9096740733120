import m from 'mithril'


const Page =  {

    view: function (vnode) {

        return [
            m('.page.page_404',
                m('.p-10',
                    m('h1', '404 - Page does not exist'),
                    m('a.my_link',
                        {
                            href: '/',
                        },
                        'Return Home')
                )
            )
        ]
    }

}


export default Page