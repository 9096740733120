import m from 'mithril'

import Reviews from "../../models/Reviews"

import Review_box from '../components/reviews/review_box'


const Page = {

    oninit: (vnode) => {
        Reviews.load(() => {
            vnode.state.reviews = Reviews.list
        })
    },

    view: function (vnode) {


        return [

            m('.page.reviews_page',

                // m('.p-10',
                m('h1.default_h1', 'Why You Should Read...'),
                m('h2.default_h2', 'Short reviews on the most interesting short stories in the library.'),
                m('.d_flex.d_centre.flex_wrap.flex_gap_large.p-20',

                    // Object.keys(vnode.state.reviews_by_date).map(date => [
                    //     // m('.date', date),
                    //     vnode.state.reviews_by_date[date]

                    (vnode.state.reviews || ([{ skeleton_key: 1 }, { skeleton_key: 2 }, { skeleton_key: 3 }, { skeleton_key: 4 }, { skeleton_key: 5 }, { skeleton_key: 6 }]))
                        .sort((a, b) => b.publish_date - a.publish_date)
                        .map(review => {

                            return [
                                m(Review_box, { review_code: review.code, key: review.skeleton_key })
                            ]
                        })
                    // ])
                )
                // )

            )
        ]
    }
}


// const review_box = {
//     view: vnode => {
//         return [

//         ]
//     }
// }


export default Page