import m from 'mithril'

import Stories from '../../models/Stories'
// import story_block from '../components/story_block'
import quote_bar from '../components/quote_bar'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const Page = {

    oninit: (vnode) => {

        vnode.state.series_list = [
            {
                series_name: 'Sherlock Holmes',
                desc: "A genius private detective solves dastardly plots and conspiracies, spurned on by curiosity as much as moral purpose. Published in the late 19th century and early 20th century in The Strand (British magazine) and Collier’s (U.S. magazine) Simultaneously.",
                quote: { quote: '“The lowest and vilest alleys in London do not present a more dreadful record of sin than does the smiling and beautiful countryside.” * The Adventure of the Copper Breeches', code: 'the-adventure-of-the-copper-breeches' },
                image_alt: 'A drawing of sherlock holmes and others over a dead body.',
            },
            {
                series_name: 'Conan the Barbarian',
                desc: "A warrior with a desire for adventures, fights and romances journeys through the mystical lands of Hyboria. Published in the 1930’s in Weird Tales magazine.",
                quote: { quote: '“Stung to frenzy, Conan caught up a jewel chest and hurled it with all his strength. It was a move the monster was not expecting.” -  * The Tower of the Elephant', code: 'the-tower-of-the-elephant' },
                image_alt: ' A drawing of Conan, with a roman style garb and wielding an axe defensively.'
            },
            {
                series_name: 'Father Brown',
                desc: "An unassuming elderly priest with a wise knowledge of sinners solves murders where no one else can. Published throughout the early 20th century in various magazines and books.",
                quote: { quote: '“The most incredible thing about miracles is that they happen.” * The Blue Cross', code: 'the-blue-cross' },
                image_alt: 'A drawing of a priest in robes standing next to a sitting and contemplating Father Brown'
            },
            {
                series_name: 'Thomas Carnacki',
                desc: "An occult detective solves not merely theft and murder, but hauntings and curses. Published in the 1900’s and 1910’s in The Idler magazine.",
                quote: { quote: '“The tale of the haunting was considered as little more than an old myth, except after nightfall.” * The Thing Invisible', code: 'the-thing-invisible' },
                image_alt: 'A drawing of Thomas Carnacki upright in bed as a mysterious shadowy figure leans over him.'
            },

            {
                series_name: 'Solomon Kane',
                desc: "A honour-bound but violent puritan roams the world in search of evil. Published in the 1920’s in Weird Tales magazine.",
                quote: { quote: '“I have wearied of the game at last, Monsieur—and you die.” * Red Shadows', code: 'red-shadows' },
                image_alt: 'The front page of Skulls in the Stars, showing a black sillhouette lurching after an unwary man.'
            },

        ]

        Stories.load_series(() => {
            vnode.state.series_list.forEach(series_basics => {
                series_basics.stories = Stories.series.filter(story => story.series == series_basics.series_name)

            })
            m.redraw()
        })

    },

    view: function (vnode) {

        return [
            m('.page.series_page',
                m('.max-800',
                    m('h1.default_h1', 'Short Story Series'),
                    m('h2.default_h2', 'Long running series of stories with iconic recurring characters.'),


                    vnode.state.series_list.map(series => [
                        m('.p-10'),
                        m('.p-20',
                            m('.series_container',

                                m('.series_left',
                                    m('h3.', series.series_name),
                                    m('h4', series.desc),
                                    m('.d_flex.flex_gap',
                                        m('img.series_image.first_image', {
                                            src: image_base + 'series_' + (series.series_name.toLowerCase().replace(/ /g, '_')) + '.jpg',
                                            image_alt: series.image_alt
                                        }),
                                        m('.example_titles', and_others(series.stories))
                                    ),

                                    m('a.heavy_button.find_library_button',
                                        {
                                            href: '/stories?search=' + series.series_name,

                                        },
                                        m('.d_computer', 'Filter the Library for ' + series.series_name),
                                        m('.d_phone', 'Find in the Library'),

                                        m('img', {
                                            src: image_base + 'books-180x180.png',
                                            alt: 'A pixel image of differently coloured books.'
                                        })
                                    ),
                                ),
                                m('img.series_image.second_image', {
                                    src: image_base + 'series_' + (series.series_name.toLowerCase().replace(/ /g, '_')) + '.jpg',
                                    image_alt: series.image_alt
                                }),


                            ),

                            // m('.block_arrangement',
                            //     (series.stories || [{ skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }]).map(story => [
                            //         m(story_block, { code: story.code, key: story.code })
                            //     ])
                            // ),



                            // m('.p-10'),
                        ),


                        m(quote_bar, { quote: series.quote }),
                    ]),

                    m('.p-10')
                )
            )

        ]
    }
}


export default Page

let and_others = (stories, limit = 3) => {
    if (!stories) return ''

    let names = stories.map(story => story.title)

    if (names.length < 2) return names[0]

    let unfinished = false
    if (limit < names.length) unfinished = true

    names = names.slice(0, limit)

    if (unfinished) {
        return `${names.join(', ')} and more.`
    }

    else {
        let last_name = names[names.length - 1]
        let all_but_last = names.slice(1, names.length)
        if (all_but_last.length == 1) return `${all_but_last[0]} and ${last_name}.`
        return `${all_but_last.join(', ')} and ${last_name}.`
    }
}