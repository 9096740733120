/*
Title
Author
Description
Blurb
Genres
Published 
Words/Time
Rating
Image


Read
PDF
EPUB
REVIEW
*/
import m from 'mithril'

import Stories from '../../models/Stories'
import Text_Italiciser from './text_italiciser'
import box from './box'

const story_display = {
    oninit: vnode => {
        // return
        Stories.fetch_story_metadata(vnode.attrs.choice.story, info => {
            vnode.state.story = info
            m.redraw()
        }, true)
    },
    view: vnode => {
        return [
            m('.choice_section',
                {
                    id: vnode.attrs.id,
                    class: vnode.attrs.no_section_3 ? 'short_choice_section' : []
                },
                m(title_display, {
                    code: vnode.attrs.choice.story,
                    story: vnode.state.story,
                    show_time: vnode.attrs.show_time,
                    no_section_3: vnode.attrs.no_section_3,
                    no_author: vnode.attrs.no_author,
                    no_review_link: vnode.attrs.no_review_link,
                    no_description: vnode.attrs.no_description,
                }),

                !vnode.attrs.no_description ?
                    m('.collection_reading',
                        m('p.',

                            m(Text_Italiciser,
                                { text: vnode.attrs.choice.description || vnode.state.story?.description }
                            )
                        )
                    ) : [],
            )
        ]
    }
}

const title_display = {
    view: vnode => {
        return [
            m('.scatter',
                m(scatter_section_1,
                    {
                        story: vnode.attrs.story,
                        no_author: vnode.attrs.no_author,
                        no_description: vnode.attrs.no_description

                    }),

                m('.scatter_bottom_section',
                    m('a.scatter_section_2.picture_as_book',
                        {
                            href: vnode.attrs.story ? ('/onlinereader/' + vnode.attrs.story.code) : null,
                            onmouseenter: () => vnode.state.section_1_hover = true,
                            onmouseleave: () => vnode.state.section_1_hover = false,
                            onfocus: () => vnode.state.section_1_hover = true,
                            onfocusout: () => vnode.state.section_1_hover = false,

                        },
                        m('.remove_default_link_styling',

                            m(box, {
                                story: vnode.attrs.story,
                                active: vnode.state.section_1_hover,
                                half_flip: vnode.state.more_info_hover,
                                flip: vnode.state.flip,
                                show_time: vnode.attrs.show_time,
                                loading: vnode.attrs.loading || 'lazy',

                            })
                        ),

                        vnode.attrs.story ? m('.box_read_button.heavy_button',
                            'Read'
                        ) : []
                    ),

                    // (vnode.attrs.story && !vnode.attrs.no_section_3) ? m('.scatter_section_3',
                    //     m('button.scatter_info_button',

                    //         {
                    //             onmouseenter: () => { vnode.state.more_info_hover = true },
                    //             onmouseleave: () => { vnode.state.more_info_hover = false },
                    //             onfocus: () => { vnode.state.more_info_hover = true },
                    //             onfocusout: () => { vnode.state.more_info_hover = false },
                    //             onclick: () => {
                    //                 vnode.state.flip = !vnode.state.flip
                    //                 vnode.state.more_info_hover = false
                    //             },
                    //         },
                    //         vnode.state.flip ? 'Flip Back' : 'More Info'),
                    //     m('a.scatter_button.download_link', {
                    //         title: vnode.attrs.story.title + '.epub',
                    //         href: '/storiesepub/' + vnode.attrs.story.code + '.epub',
                    //         target: '_blank',
                    //     }, m(book_icon), 'EPUB'),
                    //     m('a.scatter_button.download_link', {
                    //         title: vnode.attrs.story.title + '.pdf',
                    //         href: '/storiespdf/' + vnode.attrs.story.code + '.pdf',
                    //         target: '_blank',
                    //     }, m(PDF_icon), 'PDF'),
                    //     (vnode.attrs.story.has_review && !vnode.attrs.no_review_link) ? m('a.scatter_button.my_link', { href: '/review/' + vnode.attrs.story.code }, m(star_icon), 'Recommended') : [],
                    // ) : []
                )
            )
        ]
    }
}

const scatter_section_1 = {
    oncreate: vnode => {
        vnode.state.overflow = vnode.dom.scrollHeight != vnode.dom.clientHeight
    },
    onupdate: vnode => {
        vnode.state.overflow = vnode.dom.scrollHeight != vnode.dom.clientHeight
    },
    view: vnode => {
        return [
            m('a.scatter_section_1',
                {
                    tabindex: vnode.state.overflow ? 0 : -1,
                    href: vnode.attrs.story ? '/onlinereader/' + vnode.attrs.story.code : [],
                    onmouseenter: () => {
                        vnode.state.section_1_hover = true
                    },
                    onmouseleave: () => {
                        vnode.state.section_1_hover = false
                    }
                },
                m('.scatter_title_and_author', vnode.attrs.story ? [
                    m('span.scatter_title', vnode.attrs.story.title),
                    !vnode.attrs.no_author ? m('span.scatter_author', [' by ', vnode.attrs.story.author]) : []
                ] : m('.thin_word_shimmer', '-')),
                m('.scatter_section_1_line'),

                m(scatter_blurb, { story: vnode.attrs.story, no_description: vnode.attrs.no_description })
            )
        ]
    }
}


const scatter_blurb = {

    view: vnode => {
        return [
            m('.scatter_blurb',
                vnode.attrs.story ?
                    [
                        vnode.attrs.no_description ?
                            [
                                (vnode.attrs.story.description ? m('', vnode.attrs.story.description) : []),
                                (vnode.attrs.story.description ? m('br') : []),
                                m('.italics', vnode.attrs.story.blurb)
                            ] :
                            m('.italics', vnode.attrs.story.blurb)


                    ]
                    : m('.text_shimmer',
                        [...Array(3)].map(() => [
                            m('.text_shimmer_line_cover')
                        ])
                    )
            ),
        ]
    }
}
export default story_display
