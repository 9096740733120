import m from 'mithril'

import Genres from '../../models/Genres'
import { capitalise_first } from '../../../server/logic/formatter'

const s3 = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'


const Page = {

    view: function (vnode) {

        return [

            m('.page.genres_page',

                m('h1.default_h1', 'Best of Genres'),
                m('h2.default_h2',
                    'My picks for the best classic short stories of each genre.',
                    //Ranging from the historical greats of Poe and Chekhov to lesser known C. C. Macapp and 
                ),


                m('.genres_table',

                    Genres.list
                        .map(genre => [

                            m('a.new_genre_box.',
                                {
                                    href: '/collection/' + genre.code
                                },

                                m('.new_genre_box_section_1',
                                    [...Array(3)].map((_, i) => [
                                        m('.new_genre_box_image',
                                            m('img.', {
                                                loading: 'lazy',
                                                style: Genres.get_style(genre.hue),
                                                src: (s3 + (genre.images ? genre.images[i].src : genre.image)) + '.jpg',
                                                alt: (genre.images ? genre.images[i].alt : genre.image_alt),

                                            }),
                                            m('.image_shade', { style: 'background-color:' + genre.colour }),
                                            m('.new_genre_box_image_border')
                                        )
                                    ])
                                ),

                                // m('.genre_split'),

                                m('.new_genre_box_section_2',

                                    m('p',

                                        genre.stories ?
                                            genre.stories.map(story => [
                                                story,
                                                m('br')
                                            ]) : [],

                                        'and more...'

                                    )
                                ),

                                m('.genre_split'),

                                m('p.new_genre_box_section_0', m('i', genre.intro)),

                                m('.new_genre_box_button',
                                {
                                    // style: 'border-color: ' + genre.colour,
                                    // + ';color: ' + genre.colour,
                                    //background: white; 
                                },
                                [
                                    'Best of ',
                                    m('b', capitalise_first(genre.code))
                                ]
                            ),
                            )
                        ])
                ),
                m('.p-10'),

            )
        ]
    }
}


export default Page