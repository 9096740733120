import m from 'mithril'
// import skip_button from '../components/skip_button'

import story_display from '../components/story_display'
import quote_bar from '../components/quote_bar'

const Page = {
    view: function (vnode) {

        return [
            m('.page.collection_page.five-minute-page',
                {
                    itemscope: true,
                    itemtype: 'https://schema.org/Guide'
                },
                m('h1.default_h1', '5 Minute Stories'),
                m('h2.default_h2', 'The shortest of the short. Quality stories below 10 minutes.'),

                m(quote_bar, { quote: { quote: '“She’d better hurry; we’ll miss it!” * All Summer in a Day', code: 'all-summer-in-a-day' } }),

                // m(skip_button),

                m('.collection_padding',

                    m(opening_text),

                    m('.d_flex.flex_wrap.flex_gap_large.d_centre.d_centre',
                        TITLES.map((choice, i) => m(story_display, { choice: choice, show_time: true, id: i == 0 ? 'first_story' : '' }))
                    )
                )
            )
        ]
    }
}
export default Page

const opening_text = {
    view: vnode => {
        return [
            m('.opening_reading',
                m('p.',
                    `You won’t be able to read anything but poetry and `,
                    m('a.my_link', { href: '/author/aesop' }, `Aesop`),
                    `'s fables in under five minutes. But past that you can start to squeeze in stories like the Lovecraft Horror `,
                    m('a.my_link', { href: '/onlinereader/the-terrible-old-man' }, `The Terrible Old Man`),
                    `, or the Chekhov’s silly comedy `,
                    m('a.my_link', { href: '/onlinereader/the-orator' }, `The Orator`),
                    `. Scroll further down for longer stories or head over to the `,
                    m('a.my_link', { href: '/stories' }, `Library`),
                    ` to sort and filter by word length.`
                ),
            ),
        ]
    }
}


const TITLES =
    [
        { story: 'invictus' },
        { story: 'alone' },
        { story: 'the-mice-and-the-weasels' },
        { story: 'the-daylight-is-dying' },
        { story: 'the-miller,-his-son,-and-their-ass' },
        { story: 'in-defence-of-the-bush' },
        { story: 'the-geebung-polo-club' },
        { story: 'the-man-from-snowy-river' },
        { story: 'the-raven' },
        { story: 'the-terrible-old-man' },
        { story: 'the-orator' },
        { story: 'a-chameleon' },
        { story: 'the-cats-of-ulthar' },
        { story: 'the-feast-of-nemesis' },
        { story: 'vanka' },
        { story: 'the-pearl-of-love' },
        { story: 'the-emperors-new-clothes' },
        { story: 'a-transgression' },
        { story: 'luck' },
        { story: 'sredni-vashtar' },
        { story: 'allez!' },
        {story: 'the-haunted-mind'},
        {story: 'the-last-incantation'},
        { story: 'all-summer-in-a-day' },
        { story: 'the-hand' },
        { story: 'an-evening-guest' },
        { story: 'misery' },
        { story: 'the-horrible' },
        { story: 'a-burlesque-biography' },
        { story: 'and-all-the-earth-a-grave' },
    ]