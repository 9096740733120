import m from 'mithril'
import { number_to_text } from '../../logic/Formatter'
import quote_bar from '../components/quote_bar'


import story_display from '../components/story_display'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'


const Page = {
    view: function (vnode) {

        return [
            m('.page.collection_page',
                {
                    itemscope: true,
                    itemtype: 'https://schema.org/Guide'
                },
                m('h1.default_h1', 'Librarian’s Picks'),
                m('h2.default_h2', 'My top ', number_to_text(TITLES.length), ' short stories of all time.'),

                m(triple_image),

                // m(skip_button),

                m(quote_bar, {quote: {quote: '“I thank whatever gods may be - For my unconquerable soul.” * Invictus', code: 'invictus'}}),


                m('.collection_padding',

                    m(opening_text),

                    // m('.d_flex.flex_wrap.flex_gap_extra_large .d_centre.d_centre',
                    m('',

                        TITLES.map((choice, i) => m(story_display, { choice: choice, id: i == 0 ? 'first_story' : '' }))
                    )
                )
            )
        ]
    }
}


export default Page

const opening_text = {
    view: vnode => {
        return [
            m('.opening_reading',



                m('p', 'Hello, it’s me, your local, online librarian, and these are my current ', number_to_text(TITLES.length), ' favourite short stories of history. This list is not based on any academic agreement, nor is it grounded on ratings from the public, it is simply my personal favourites of the five hundred short stories and poems that I have read while creating this website.'),
                // m('p',
                //     ''
                // ),

                // m('p.',
                //     'And I have a bias for sure. But so does your local librarian. I enjoy Fantasy, Sci-Fi and other ‘genre’ works moreso than literary works and poetry. But that just means if I advise a poem like ',
                //     m('a.my_link', { href: '/onlinereader/clancy-of-the-overflow' }, `Clancy of the Overflow`),
                //     `, or a literary work such as `,
                //     m('a.my_link', { href: '/onlinereader/black-fog' }, `Black Fog`),
                //     ` then you can be pretty sure it’s worth your time.`,
                // ),
            ),
        ]
    }
}

const TITLES = [
    {
        story: 'a-dream-of-armageddon',
        description: `*A Dream of Armageddon* is an epic adventure told through a series of dreams. H. G. Wells packs an entire odyssey into this 10,000 word story, one which is full of love and loss, paradise and war.
A man on a train is approached by a stranger who is eager to talk about his dreams, he chronicles a romantic epic that unfolded to him over many nights of sleep.`
    },
    {
        story: 'clancy-of-the-overflow',
        description: `The classic Australian bush Poet, Banjo Paterson, provides us with a calling to the outback. *Clancy of the Overflow* is a harsh criticism of how dreary the rat race of city life could feel even in the 19th century.
A simple city man wonders what a country friend of his is up to, and contemplates the difference between his life in an office and the life of his adventurous friend.`
    },
  
    {
        story: 'the-man-who-could-imitate-a-bee',
        description: `A genius story about unwanted fame and one-hit-wonders from an author only famous for one story himself —*The Most Dangerous Game*. *The Man Who Could Imitate a Bee* is a hilarious tale with a lovable protagonist.
A young scientist stoops to cheap comedy to gain the interest of a girl and her peers, but the attention quickly turns sour for the young man. `
    },
    {
        story: 'the-dream-of-a-ridiculous-man',
        description: `*The Dream of a Ridiculous Man* provides an answer for nihilism that entire philosophies fail to give. It tackles the misery of a suicidal man with a simple idea and the most beautiful depictions of humanity’s purpose put to pen.
A man resolves to end his life that night, but a panicked girl on the street begging for help causes him to hesitate.`
    },
    {
        story: 'the-unexpected',
        description: `Jack London is a master of writing struggle and suffering, but this story knocks his other adventures out of the park by moving from merely man vs wild to man vs man (or in this case, woman vs man).
A woman is thrust out from a safe and predictable life in the city and into a crazy and wild life in the Canadian frontier.`,
    },
    {
        story: 'the-outrage',
        description: `Through sly comedy, Kuprin tackles a deep historical issue of violence in Russia.
A charismatic leader of the ‘Association of Thieves’ turns up to the courts to defend their ‘profession’ after being accused of murder.`
    },
    {
        story: 'the-pearl-of-love',
        description: `A short fable on devotion by H. G. Wells with an ingenious twist meaning at the end.
A prince who lost his beloved princess vows to spend the rest of his life building a monument of her image so glorious that people will come from far and wide just to see it’s beauty.`
    },
    {
        story: 'the-chain-of-aforgomon',
        description: `Clark Ashton Smith gives us a mind-bending story that is a great introduction to the hyper-imagination of one of history’s most underrated fantasy authors.
In the diary of a man disappearing from all records of his existence, he writes of the use of a drug to see into his past lives.`,
    },
    {
        story: 'the-black-cat',
        description: `In *The Black Cat*, Edgar Allan Poe masters the sense of paranoia.
A man suspects his wife’s adopted, black cat to be the source of all his almost paranormal bad luck.`,
    },
    {
        story: 'a-vintage-from-atlantis',
        description: `If a story is relayed by a pirate in a bar then you know it’s going to be worth your time.
A crew of buccaneers find a mysterious bottle on a beach, temptingly smelling like a wine from a different era, aged to perfection. But when they drink it they start to see strange visions in the sea.`
    },
    {
        story: 'a-friend-of-napoleon',
        description: `Another genius tale from Richard Connell, this one is more wacky than *The Man Who Could Imitate a Bee*, but has a touching ending to seal it off.
A lonely wax museum worker must save his friends when the museum closes down.`
    },
    {
        story: 'black-fog',
        description: `Kuprin writes on the clash of a city man and a country man in *Black Fog*. It is another poignant critique of the rat race of society, but with a tragic ending where the fog of the big town wins.
A rambunctious Ukrainian barges back into his old Petersburg friend’s life determined to ‘Conquer’ the city. `
    },
    {
        story: 'alone',
        description: `*Alone* is a pure and honest piece. Although published after his death, evidence surrounding the original manuscript suggests this was written by Poe when he was only twenty. Whenever he wrote it, he chose to keep it to himself and never publish the autobiographical verses.
A man writes on the feeling of being different and alone since birth.`
    },
    {
        story: 'a-guest-of-ganymede',
        description: `An adventurous, heist, thriller from the unknown C. C. Macapp. Despite never reaching much success as an author aside from a few publications in sci-fi magazines, *A Guest of Ganymede* is a fantastic idea for an adventure in space, terrifically executed in its pacing.
Gil Murdoch has ferried his wealthy, blind client to a hidden, alien hospital where they will heal his eyes with their firmly-guarded, cure-all virus. But Gil isn’t satisfied with a simple payment, and has other plans for the priceless drug.`
    },
    {
        story: 'the-country-of-the-blind',
        description: `*The Country of the Blind* starts off as a regular adventure, but over the course of the story, Wells delves deeper than you would expect from a short story could into the philosophical consequences of its own plot. 
A man  falls off a mountainside into a mysterious town that has lived isolated from the rest of the world for generations. The newcomer struggles to fit in with the locals, who are all very suspicious of the two sensitive objects stuck in the middle of his face, and at various points chase him around and threaten to pull them out of his head.`
    },
    {
        story: 'the-silver-key',
        description: `Lovecraft explores the suffering of a man wishing to dream like he could as a child.        
Randolph Carter journeys to his ancestral home to rekindle the dreams of his youth, which have been replaced with a jaded reality.`
    },
    {
        story: 'the-double-shadow',
        description: `*The Double Shadow* is an energetic gothic fantasy, that shows you the villanous side of a fantasy world.
Two necromancers live on a castle on a cliff. In their ambitions to discover all there is to know about the dark side of magic, they bite off more than they can chew.`
    },
    {
        story: 'cool-air',
        description: `Lovecraft was a man scared of many things, often taking inspirations from his nightmares. But what could possible have been the inspiration for this story about the fear of cold air?
A man finds himself lodging underneath a scientist that never leaves his room full of contraptions and chemicals, and kept to freezing temperatures all day long. It is written in the typical, fancy tongue of Lovecraft, but more creepy and mysterious than horrifying as his other stories usually are.`
    },
    {
        story: 'invictus',
        description: `*Invictus* is the literary embodiment of courage and resilience, and a cornerstone of British cultural pride, having been quoted by Churchill in his books and speeches. It is said that W. E. Henley wrote this poem to express his pride in having braved the amputation of his leg.`
    },
    {
        story: 'a-reputation',
        description: `*A Reputation* is a criticism of our obsession with suffering and tragedy.
Saunders Rook wants very badly to be as popular as the others of his club of liberal professionals, so he blurts out the first thing he can think of that will get him some attention: a plan to commit suicide. Unfortunately, he leaves himself with no choice but to stick by what he says. This satirical piece deeply criticises our desire for the violent spectacle and is well worth the read.`,
    },
    {
        story: 'johnny-pye-and-the-fool-killer',
        description: `A deep and thought-provoking story hidden under a layer of silliness. *Johnny Pye and the Fool-Killer* is a timeless masterpiece.
A young boy’s parents call him a fool and warn him the fool-killer will get him someday. So the impressionable lad runs away and spends years trying to find an adult that can make a clever man out of him.`
    },
    {
        story: 'cain',
        description: `Perhaps more relevant than ever, Kuprin writes of an unnamed expedition by the Russian Empire.
After sentencing an old man to death, he is visited at night by the very same man. How did he escape? What does he want? Who is he?`
    },
    {
        story: 'in-the-vault',
        description: `Although still filled with fear, this Lovecraft story is stripped of the typical terrifying monstrosities and psychic dread to reveal a purely gothic Lovecraft story.
An undertaker grows lax in his care for the burial process. Then one day he accidentally locks himself in a vault with several yet-to-be buried coffins and learns to regret having ever disrespected the dead.`
    },
]



const triple_image = {
    view: vnode => {


        return [
            m('.new_genre_box_section_1',
                [

                    {
                        image: 'shotgun.jpg',
                        hue: '160deg',
                        colour: 'rgb(179, 60, 0)',
                        alt: 'A cracked shotgun with smoke rising from the empty barrels. Graphite filter.'

                    },

                    {
                        image: 'chains.jpg',
                        hue: '110deg',
                        colour: 'rgb(179, 0, 89)',
                        alt: 'Long, iron chains falling onto a tense but open hand, palm up. Graphite filter.'

                    },

                    {
                        image: 'drovers.jpg',
                        hue: '179deg',
                        colour: 'rgb(113, 78, 15)',
                        alt: 'Three men with wide-brimmed hats riding horses in the outback.'
                    },

                ].map((story, i) => [
                    m('.new_genre_box_image',
                        m('img.', {
                            loading: 'lazy',
                            style: story.hue ? 'filter: grayscale(1) invert() sepia() saturate(150%) hue-rotate(' + story.hue + ') invert(1) brightness(1.2);' : '',
                            src: image_base + story.image,
                            alt: story.alt,
                        }),
                        m('.image_shade', {
                            style: 'background-color:' + story.colour
                        }),
                        m('.new_genre_box_image_border')
                    )
                ])
            )
        ]
    }
}