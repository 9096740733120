import m from 'mithril'
import gradient_section from '../components/gradient_section'

import quote_bar from '../components/quote_bar'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

// import quote_wall from './quote_wall'
// import gallery from './gallery'
// import Stories from '../../models/Stories'
// import quote_generator from '../components/quote_box'

const Page = {

    view: function (vnode) {

        // return m(gallery)
    
        // return [
        //     [...Array(80)].map(() => [m(quote_bar), m('.p-10')],
        //     )
        // ]

        return [
            m('.about_page.page',

                m(gradient_section,
                    m('.max-700',

                        m('h1.default_h1', 'About the Library'),
                        m('h2.default_h2', 'A free, online collection of short stories from the public domain.'),

                        m('.p-10'),
                    )
                ),

                m('.max-700',

                    m(quote_bar),


                    // m('.screenshot_image',
                    //     m('img', {
                    //         src: image_base + 'library_screen.png'
                    //     })
                    // ),
                    // m('.screenshot_image',
                    //     m('img', {
                    //         src: image_base + 'author_screen.png'
                    //     })
                    // ),

                    m('.general_reading_font',

                        m('.p-20.general_reading_font',
                            m("h3.default_h3", "The Purpose of the Library"),



                            m('p',

                                'This website is an individual endeavour to make the best of public domain short stories easily accessible to read at all times, for everyone, for free. I have formatted over a thousand works, from the mysteries of Edgar Allan Poe, to the horrors of H. P. Lovecraft, the adventures of Jack London, and the fantasies of H. G. Wells. All of the most iconic stories of history are here.'

                                // `We have inherited a cornucopia of art from previous generations of authors. Short stories from every century and from around the world. With the `,
                                // m('i', `Library of Short Stories`),
                                // ` I have formatted over a thousand of these works for free online consumption.`
                            ),
                            m(magazines_display),

                        ),


                        // m('.p-20.general_reading_font',


                        //     m('p', 'If you have feedback for the website, or have an author whose works you would like to see on the library, please contact me by ',
                        //         m('a.external_link', {
                        //             href: 'mailto:eclauthor@gmail.com'
                        //         }, 'email.')
                        //     ),

                        //     m('p', 'Get Reading.'),
                        //     m('p', m('a.my_link', { href: '/stories' }, 'Browse The Library'), '.')
                        // ),

                        // m('.d_flex.flex_gap.d_centre.flex_wrap',

                        //     m(library_button),
                        //     m(collections_button),
                        //     m(reviews_button),

                        // ),




                        m(quote_bar),

                        m('.p-20',

                            m("h3.default_h3", "Formats"),

                            'Each story is available as simple text online and downloadable as a PDF or EPUB.',

                            m('.reader_screens',
                                m('.screenshot_image.reader_computer',
                                    m('.screenshot_object',

                                        m('img', {
                                            src: image_base + 'onlinereader_screen.png',
                                            alt: 'A screenshot of a story named The Chain of Aforgomon'

                                        }),

                                        m('.border')
                                    )
                                ),
                                m('.screenshot_image.reader_phone',
                                    m('.screenshot_object',

                                        m('img', {
                                            src: image_base + 'onlinereader_phone_screen.png',
                                            alt: 'A screenshot of a story named The Chain of Aforgomon for the phone.'
                                        }),
                                        m('.border')
                                    )

                                ),
                                m('.screenshot_image.reader_pdf',
                                    m('.screenshot_object',

                                        m('img', {
                                            src: image_base + 'onlinereader_pdf.png',
                                            alt: 'A PDF of a story named The Chain of Aforgomon.'
                                        }),
                                        m('.border')
                                    )
                                ),
                                m('.screenshot_image.reader_epub',
                                    m('.screenshot_object',
                                        m('img', {
                                            src: image_base + 'onlinereader_epub.png',
                                            alt: 'An EPUB of a short story.'
                                        }),
                                        m('.border')
                                    )
                                )
                            ),
                        ),

                        m(quote_bar),
                        m('.p-20',
                            m("h3.default_h3", "How to Discover Stories"),


                            m('p',
                                `The `,

                                m('a.my_link', {
                                    href: '/stories',
                                },
                                    `Library`
                                ),
                                ` page can be used to filter and sort stories by genre, length, date, author and rating.
`), m('p', `
                                The `, m('a.my_link', {
                                    href: '/authors',
                                },
                                    `Authors`
                                ), ` page can be used to filter and sort authors similiarly.
                                `), m('p', `
                                The `, m('a.my_link', {
                                    href: '/discover',
                                },
                                    `Discover`
                                ), ` page holds recommendations and collections  including the top classics and the best of each genre.
                                `,


                                    // 'Find stories directly in the ',
                                    // m('a.my_link', {
                                    //     href: '/stories',
                                    // },
                                    //     `Library`
                                    // ),
                                    // ' and authors in the ',

                                    // m('a.my_link', {
                                    //     href: '/authors',
                                    // },
                                    //     `Authors`
                                    // ),

                                    // ' page, or browse curated lists and reviews of the best stories in the ',
                                    // m('a.my_link', {
                                    //     href: '/discover',
                                    // },
                                    //     `Discover`
                                    // ),
                                    // ' section.'
                                ),



                            m('.other_screens',

                                m('.screenshot_image.library_screen',
                                    m('.screenshot_object',
                                        m('img', {
                                            src: image_base + 'library_screen.png',
                                            alt: 'A screenshot of rows of online short story links.'
                                        }),
                                        m('.border')
                                    )
                                ),
                                m('.screenshot_image.author_screen',
                                    m('.screenshot_object',
                                        m('img', {
                                            src: image_base + 'author_screen.png',
                                            alt: 'A screenshot of rows of information on Arthur Conan Doyle.'

                                        }),
                                        m('.border')
                                    )
                                )
                            ),

                        ),


                        // m('a.my_link', {
                        //     href: '/collections',
                        // },
                        //     `Collections by Theme`
                        // ),
                        // ' and reviews in ',
                        // m('a.my_link', {
                        //     href: '/reviews',
                        // },
                        //     `Recommended`
                        // ), '.',

                        // m('p', `You can discover new stories by finding the top picks by `,
                        //     m('a.my_link', { href: '/discover' }, `topic`),
                        //     ` and `,
                        //     m('a.my_link', { href: '/genres' }, `genre`),
                        //     `. Or you can filter by author, length or a subjective rating given by me to over 120 works on the `,
                        //     m('a.my_link', { href: '/stories' }, ` library`),
                        //     ` page. Or you can let me convince you why you should read the most interesting ones on `,
                        //     m('a.my_link', { href: '/reviews' }, ` reviews.`)
                        // ),




                        m(quote_bar),
                        m('.p-20',
                            m("h3.default_h3", "The Creator"),
                            m('.profile_image',
                                m('img', { src: image_base + 'me2.jpg' }),
                            ),
                            m('.profile_name', 'Evan C. Lewis'),
                            m('p', "Evan is a Melbourne based author and web developer who writes short stories for fantasy and sci-fi magazines. You can read some of his works on his personal ",
                                m('a.external_link', { href: 'https://www.astoryperweek.com', target: '_blank' }, "website.")
                            )
                        ),

                        m(quote_bar),
                        m('.p-20',
                            m("h3.default_h3", "Original Content"),
                            m('p', "The creative design of this website is and always will be created purely without the use of artificial intelligence. All content, such as the reviews, collection introductions, author descriptions, and story descriptions; all curation, such as the collections, ratings, and genre cataloguing will always be originally designed by me: a human (Or some other human that helps out with original input). A.I. will only ever be used to help with the programmatic coding of the website, or to research objective, factual information, such as dates of first publishing or year of death."),
                            m('p', "I do not necessarily have anything against the use of A.I. nor even specifically against the use of A.I. in creative text. But I want to maintain an honest, human-centred sense of exploration of literature in this website.")
                        ),

                        m(quote_bar),

                        m('.p-20',
                            m('h3.default_h3', 'Support the Library of Short Stories'),
                            m('p.general_reading_font', 'It takes a considerable effort to design, develop and maintain the website, as well as convert and document the texts. If you would like to support me so I can keep this website alive and expand the works and features within, please consider supporting me and the website at my support page.'),
                            m('a.general_reading_font.external_link', { href: 'https://ko-fi.com/evanclewis', target: '_blank' }, 'Support the Library of Short Stories'),
                            // m('p.general_reading_font', 'Alternatively, use my sponsored link and browse from physical collections of your favourites short story authors over at Books-a-Million (U.S.A. Only).'),
                            // m('a.general_reading_font.sponsor_link', { href: 'https://www.jdoqocy.com/click-100830867-32499', target: '_blank' }, 'Purchase short stories from Books-a-million (U.S.A. Only).'),

                        ),

                        // m(quote_bar),
                        // m('.p-20',
                        //     m('h3', 'Affiliate Opportunity'),
                        //     m('p.general_reading_font', `I am looking for a non-interruptive, affiliate partnership with an online bookstore. I am hoping that browsers of this website will be able to purchase physical copies of short story compilations that include their favourite authors, and support this website at the same time. If you are part of an online bookstore or similar organisation and are interested, please `, m('a.external_link', {
                        //         href: 'mailto:eclauthor@gmail.com',
                        //     }, 'contact me'),
                        //         '.'

                        //     )
                        // )
                    )
                )
            )
        ]
    }
}


const magazines_display = {
    view: vnode => {
        return [
            m('.magazines',
                [
                    { code: 'strand', alt: 'A cover of The Strand Magazine headlined with "Sherlock Holmes outwits a German spy".' },
                    { code: 'amazing', alt: 'A cover of Amazing Stories depicting a spaceship flying past a planet with a single large city.' },
                    { code: 'weird', alt: 'A cover of Weird Tales magazine depicting a pair of eyes and hands cluthing a glass ball with vultures and a dead body inside.' },
                    { code: 'bulletin', alt: 'A cover of The Bulletin Newspaper depicting a court with several officials looking angry at a literal Kangaroo' },
                    { code: 'colliers', alt: 'A cover of Colliers Weekly depicting a man with a top hat and red scarf with a snowball whizzing by.' }
                ].map((mag, i) =>
                    m('.magazine',
                        {
                            class: (i > 2 ? 'd_computer' : '')
                        },
                        m('img', {
                            src: image_base + 'cover-' + mag.code + '.jpg',
                            alt: mag.alt
                        }),
                        m('.border')
                    ),
                )
            ),

        ]
    }
}




export default Page



