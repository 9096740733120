
import m from 'mithril'

const Page = {

    view: function (vnode) {

        return [

            m('.page.privacy_page',
                // m('.p-10',
                //     [
                m("h1.default_h1",
                    "Privacy Policy of the Library of Short Stories"
                ),
                // m("h2.default_h2",
                //     "External links and zero data collection."
                // ),
                m('.p-20',

                    m("h3.default_h3",
                        "Your Data"
                    ),

                    m('p', 'The Library of Short Stories uses local session storage for the settings of Dark Mode and Open Dyslexic Font. This data is for practical use, stored only locally and not used for any advertising purposes. Therefore it is exempt from needing any of those ', m('i', 'annoying'), ' cookie banners.'),

                    m("h3.default_h3",
                        "Links to Other Sites"
                    ),
                    m("p",
                        "Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services."
                    ),
                    m("h3.default_h3",
                        "Affiliate Links"
                    ),
                    m("p",
                        "This site uses affiliate links to help fund its continuation. Clicking on these links may result in tracking cookes used by external sites. On this site, affiliate links are easily identifiable  by use of words such as ‘purchase’ and ‘buy’."
                    ),
                    m("h3.default_h3",
                        "Children’s Privacy"
                    ),
                    m("p",
                        "This website does not collect personal identifiable information from children under 13, because it does not collect personally identifiable information from anyone."
                    ),
                    m("h3.default_h3",
                        "Changes to This Privacy Policy"
                    ),
                    m("p",
                        "We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page."
                    ),
                    m("h3.default_h3",
                        "Contact Us"
                    ),
                    m("p",
                        "If you have any questions or suggestions about our Privacy Policy, do not hesitate to ", m('a.my_link', { href: 'mailto:eclauthor@gmail.com' }, "contact us.")
                    )
                )
                //     ]
                // )
            )
        ]
    }

}


export default Page