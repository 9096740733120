import { redraw } from 'mithril'

const Authors = {
    loaded: false,

    list: [],

    load: (cb = () => { }, hard_load = false) => {

        if (!hard_load && Authors.list && Authors.list.length > 0) return cb()

        if (Authors.loading_list) {
            Authors.callback_queue = Authors.callback_queue || []
            Authors.callback_queue.push(cb)
            return
        }
        else {

            Authors.loading_list = true
            //LOAD
            fetch('/authorslist')
                .then(json_result => json_result.json())

                .then(authors => {

                    Authors.list = authors
                    Authors.loading_list = false
                    Authors.loaded = true
                    cb()
                    if (Authors.callback_queue) {
                        Authors.callback_queue.forEach(waiting_callback => {
                            waiting_callback()
                        })
                        Authors.callback_queue = []
                    }

                    redraw()
                }).catch(err => {
                    console.error(err)
                })
        }
    },

    load_writing_style_compressed: (code, cb = () => { }) => {
        let url = "/writing-stylegzip/" + code + '.txt'

        fetch(url)
            .then(result => {
                if (result.ok) {
                    result.text()
                        .then((result) => {
                            if (result) {
                                cb(result)
                            }
                            else {
                                Authors.load_writing_style(code, cb)
                            }
                        })
                }
                else {
                    Authors.load_writing_style(code, cb)
                }
            })
    },

    load_writing_style: (code, cb = () => { }) => {

        fetch('/writing-style/' + code + '.txt')
            .then(json_result => {
                if (json_result.ok) {

                    json_result.json()
                        .then(writing_style => {
                            cb(writing_style.text)
                        })
                        .catch(err => {
                            console.error(err)
                        })
                }
                else {
                    console.error(json_result.status)
                }
            })
    },

    get_author_main_genres: (options) => {

        //RULES
        //A certain fraction of the books must have this genre
        let fraction_threshold = 5
        //You must have at least this many books to have this many main genres
        let min_books = 3


        if (options.author) {
            let author = options.author

            let genres_sorted = author.genres.sort((a, b) => author.genres_spread[b] - author.genres_spread[a])

            let main_genres = []

            genres_sorted.forEach((genre, i) => {

                //Add first
                if (i == 0) main_genres.push(genre)

                //Add equal genres
                else if (author.genres_spread[genre] == author.genres_spread[genres_sorted[0]]) main_genres.push(genre)

                else if ((author.genres_spread[genre] > (author.works.length / fraction_threshold)) && (author.genres_spread[genre] >= min_books)) main_genres.push(genre)
            })


            // main_genres = author.genres
            //     .filter(g => author.genres_spread[g] > (author.works.length / fraction_threshold))


            if (!main_genres.length) {
                console.error("352")
                return author.genres
            }
            return main_genres
        }

        // if (options.author) {
        //     let author = options.author

        //     let main_genres

        //     main_genres = author.genres
        //         .filter(g => author.genres_spread[g] > (author.works.length / fraction_threshold))
        //         .sort((a, b) => author.genres_spread[b] - author.genres_spread[a])

        //     if (!main_genres.length) return author.genres
        //     return main_genres
        // }


        console.error("ERROR GETTING MAIN GENRES")
        return []
    },

    get_author_top_genre: author => {


        return author.genres.reduce(
            (acc, next) => {
                return ((author.genres_spread[next] > (author.genres_spread[acc] || 0)) ? next : acc)
            },
            author.genres[0]
        )
    },

     get_n_works: author => {

        let n_poetry = 0
        if (author.genres_spread) {
            n_poetry = author.genres_spread['poetry'] || 0
        }
        let n_works = author.works.length
        let n_stories = n_works - n_poetry

        let text = [n_stories ? [n_stories, ' stor', [n_stories > 1 ? 'ies' : 'y']] : '', (n_stories && n_poetry) ? ' and ' : '', n_poetry ? [n_poetry, ' poem', [n_poetry > 1 ? 's' : '']] : '']
        return text
    }
}

export default Authors
