
import m from 'mithril'
import quote_bar from '../components/quote_bar'
import { star_icon } from '../components/icons'
import Genres from '../../models/Genres'
import { capitalise_first } from '../../../server/logic/formatter'
import gradient_section from '../components/gradient_section'

const Page = {

    view: function (vnode) {

        return [

            m('.page.how_page',
                m(gradient_section,

                    m('.max-700',
                        m("h1.default_h1",
                            "Accessibility"
                        ),
                        m("h2.default_h2",
                            "How To Use the Library."
                        ),
                        m('.p-10')
                    )
                ),
                m('.max-700',

                    m(quote_bar),

                    m('.p-20',

                        m('h3.default_h4', 'Genre Colouring'),
                        m('p',
                            Genres.list.map(g => [
                                m('',
                                    m('.colour_square', { style: 'background-color: ' + g.colour }), capitalise_first(g.code)
                                )
                            ])
                        ),


                        m('h3.default_h4', 'Link Colouring'),
                        m('p',
                            'To prevent surprise downloads and redirections to external websites, I have tried to keep buttons and links consistent across the website. The rules are as follows. ',
                            m('br'),
                            m('br'),
                            m('span.button_link', 'Orange'), ' text keeps you on the same page but changes the content in some way.',
                            m('br'),
                            m('span.my_link', 'Blue'), ' text links to pages within the Library of Short Stories. ',
                            m('br'),
                            m('span.download_link', 'Purple'), ' text downloads documents from the Library of Short Stories. ',
                            m('br'),
                            m('span.external_link', 'Green'), ' text links to external sites and documents. ',
                            m('br'),
                            m('span.sponsor_link', 'Gold'), ' text links to affiliate sites. ',

                        ),

                        m('h3.default_h4', 'Library Ratings'),
                        m('p',
                            'All ratings given to works in the library are made by me as an individual (I endeavour to read every story in the library eventually). They are not objective, nor do they represent any consensus. Instead they are to be used only as a guide, like asking your local librarian for their opinion. If you would prefer not to have your browsing experience affected, you may turn off the ratings in the settings.',
                            m('br'),
                            m('br'),
                            'The ratings system is as follows:',
                            m('br'),
                            m('br'),
                            [
                                'Worthless',
                                'Weak',
                                'Worthwhile',
                                'Great',
                                'Special',

                            ].map((rating, i) => [
                                m('.small_rating',
                                    m('.stars',
                                        [...Array(i + 1)].map(() => m(star_icon,)),
                                    )
                                ),
                                rating
                            ])

                        ),



                        m('h3.default_h4', 'Reading Formats'),
                        m('p', 'Every story on the website is available as online text, PDF or EPUB'),

                        m('h3.default_h4', 'Dark Mode'),
                        m('p', 'Setting the website to Dark Mode may allow for easier reading at night time. It can be enabled at the top left of the website.'),

                        m('h3.default_h4', 'Open Dyslexic'),
                        m('p', 'Open Dyslexic font allows some readers a more comfortable experience. It can be enabled in settings at the top right of the website.'),

                        m('h3.default_h4', 'Screen Reading'),
                        m('p', 'I aim to keep this website fully accessible to people using screen readers and keyboard navigation.'),

                        m('h3.default_h4', 'Continual Improvement'),
                        m('p',
                            'If you have any recommendations on how to make this website easier to use, for example for screen reading technology, please ', m('a.my_link', { href: 'mailto:eclauthor@gmail.com' }, "email me.")
                        )
                    )
                )
            )
        ]
    }
}

export default Page