const Formatter = {
    capitalise_first: text => {
        if (!text) return ''
        return text[0].toUpperCase() + text.slice(1)
    },

    make_url_friendly: text => {
        if (!text) return text
        text = text.replace(/é/g, 'e')
        return text.replace(/'|"|“|”|‘|’|\?| |\./g, '').toLowerCase()
    },

    my_date_to_readable: my_date => {
        let split_date = my_date.split('-')
        return months[parseInt(split_date[1])] + ' ' + split_date[2]

    },



    //COPIED FROM SRC/FORMATTER
    //Parse text for online reader  (asterisk to italics)

    // │ to image
    parse_text_to_lines: text => {
        // if (text == null || text == undefined || text == '') { 
        //     console.error('No text for parse text method')
        // }
            

        //Keep italics within lines

        //Split to lines

        let lines

        lines = text

            .split(/[\n]/)

            .map(line =>
                [{ text: line, italics: false }]
            )

            .map(line => {
                if (line[0].text && line[0].text.length > 0 && line[0].text != '\r') {
                    return line
                }

                return [{ text: '\xa0', italics: false }]
            })

        //Italicise
        lines = lines.map(phrases => {
            let line = phrases[0].text

            //If line doesn't have any asterisks, return
            if (!line.includes('*')) {
                if (line[0] == '│') {
                    let data = line.replace(/│/g, '').split('|')
                    let src = data[1]
                    let alt = data[0]
                    return [{ text: alt, italics: false, image: { src: src, alt: alt } }]
                }
                else {
                    return phrases
                }

            }

            let new_phrases = []
            let italic = false
            let phrase = ''
            for (let i = 0; i < line.length; i++) {
                if (line[i] !== '*')
                    phrase += line[i]
                else {
                    if (phrase) new_phrases.push({ text: phrase, italic: italic })
                    phrase = ''
                    italic = !italic
                }
            }
            if (phrase) new_phrases.push({ text: phrase, italic: italic })
            if (new_phrases.some(phrase => phrase.text.includes('*'))) console.error('Leftover Asterisk 1')
            return new_phrases

        });

        return lines
    },
     oxford_comma: (list) => {
        if (!list) return ''
        if (!list.length) return ''
        if (list.length == 1) return list[0]
        return list.slice(0, list.length - 1).join(', ') + ' and ' + list[list.length - 1]
    },

    quotify: text => {

        let quotes = [
            ['"', '"'],
            ['\'', '\''],
            ['“', '”'],
            ['‘', '’']
        ]

        let has_quotes = false

        for (let i = 0; i < 4; i++) {
            if (text[0] == quotes[i][0] && text[1] == quotes[i][1]) has_quotes = true

        }

        if (has_quotes) {
            return text
        }
        else {
            return '"' + text + '"'
        }
    },

     alphabetise:(a, b, options = {}) => {
        // Set the options using the nullish coalescing operator
        const ignoreA = options.ignore_a ?? true; // Ignore articles at the beginning of strings by default
        const emptyFirst = options.empty_first ?? false; // Put empty strings last by default
        const caseSensitive = options.case_sensitive ?? false; // Compare strings case-insensitively by default
    
        // Check for exact equality
        if (a === b) {
            return 0;
        }
    
        // Handle empty strings
        if (!a) {
            return emptyFirst ? -1 : 1;
        }
    
        if (!b) {
            return emptyFirst ? 1 : -1;
        }
    
        // Clip the strings if needed
        let clippedA = ignoreA ? a.replace(/['"“‘’”]/g, '').replace(/^(A|An|The)\s+/, '').replace(/^(\$|£|—)/, '') : a;
        let clippedB = ignoreA ? b.replace(/['"“‘’”]/g, '').replace(/^(A|An|The)\s+/, '').replace(/^(\$|£|—)/, '') : b;
        
        // .replace(/The Adventure of /,'')
    
        // Compare the clipped strings
        if (clippedA.localeCompare(clippedB, undefined, { sensitivity: caseSensitive ? 'case' : 'base' }) === 0) {
            return 0;
        }
    
        return clippedA.localeCompare(clippedB, undefined, { sensitivity: caseSensitive ? 'case' : 'base' });
    }
}

module.exports = Formatter

const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"]
