import m, { redraw } from 'mithril'
const images_location = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

import Reviews from "../../../models/Reviews"
import Stories from '../../../models/Stories'
import Genres from '../../../models/Genres'

import { quotify } from '../../../logic/Formatter'

const review_box = {
    oninit: (vnode) => {
        Reviews.load(() => {
            vnode.state.review = Reviews.list.find(r => r.code == vnode.attrs.review_code)
        })

        Stories.fetch_story_metadata(vnode.attrs.review_code, story => {
            vnode.state.story = story
            redraw()
        }, true)
    },

    view: vnode => {
        let review = vnode.state.review
        let story = vnode.state.story
        return [
            m('a.review_tile',
                {
                    href: vnode.attrs.review_code ? ('/review/' + vnode.attrs.review_code) : null,
                },

                m('.review_tile_image',

                    story ? m('img.snippet_image', {
                        style: Genres.get_style(Genres.list.find(g => g.code == story?.genres[0]).hue),
                        src: images_location + story?.image,
                        alt: story?.image_alt
                    }) : [],
                    m('.image_shade', {
                        style: 'background-color:' + (
                            //                        colours[author?.genres ? author?.genres[0] : '']
                            story ? Genres.list.find(gen => gen.code == story.genres[0]).colour : '')
                    }),
                ),
                m('.review_tile_info',
                    m('.review_tile_line', 'Why You Should Read...'),
                    m('.review_tile_title', story ? story.title : [m('.word_shimmer.matching_half_shimmer', '-')]),
                    m('.review_tile_quote', story ? quotify(review.my_quote) :
                        m('.text_shimmer',
                            [...Array(3)].map(() => [
                                m('.text_shimmer_line_cover')
                            ])
                        )
                    ),
                    m('.review_tile_read.my_link', 'Read Recommendation')
                )
            )
        ]
    }
}

export default review_box