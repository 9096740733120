import m from 'mithril'

import Author_box from '../components/author_box'

import Collections from '../../models/Collections'
import { capitalise_first, do_something_to_style } from '../../logic/Formatter'
import story_display from '../components/story_display'
import breadcrumbs from '../components/breadcrumbs'
import Genres from '../../models/Genres'
import quote_bar from '../components/quote_bar'

import gradient_section from '../components/gradient_section'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'




const Page = {
    oninit: (vnode) => {

        Collections.load_collection_text_gzip(vnode.attrs.collection, (text) => {
            vnode.state.text = text
        })

        Collections.load(() => {
            vnode.state.collection = Collections.list.find(c => c.code == vnode.attrs.collection)

            if (!vnode.state.collection) {
                vnode.state.error = true
                return
            }


            vnode.state.breadcrumb_links = vnode.state.collection.is_genre ?

                ['/discover', '/genres'] :
                ['/discover', '/collections']
        })
    },

    view: function (vnode) {

        if (vnode.state.error) {
            return m('.page.review_page',
                m('.p-10',
                    m('h1',
                        m('', '404 - Collection not found'),
                        m('a.my_link', {
                            href: '/',
                        }, 'Return Home')
                    )
                )
            )
        }

        return [
            m('.page.collection_page',
                {
                    itemscope: true,
                    itemtype: 'https://schema.org/Guide'
                },
                // m('.under_construction', 'This page is under construction'),
                // m('.max-800',


                vnode.state.breadcrumb_links ? m(breadcrumbs, {
                    links: vnode.state.breadcrumb_links,
                    title: vnode.state.collection.title || vnode.state.collection.header_1
                }) : [],


                m(gradient_section,
                    {
                        active: (!!vnode.state.collection?.is_genre),
                        hue: vnode.state.collection?.is_genre ? Genres.list.find(g => g.code == vnode.attrs.collection).hue : false,
                        genre: vnode.state.collection?.is_genre ? vnode.attrs.collection : false,
                        // background: 'linear-gradient(124deg, transparent, rgba(255, 166, 0, 0.45), rgba(0, 255, 0, 0.45), rgba(255, 166, 0, 0.45))'
                    },

                    //H1
                    m('h1.default_h1', vnode.state.collection ? (vnode.state.collection.header_1 || vnode.state.collection.title) : ''),

                    //H2
                    m('h2.default_h2', vnode.state.collection ? vnode.state.collection.header_2 : ''),

                    m(triple_image, {
                        collection: vnode.state.collection,
                        genre: vnode.attrs.collection
                    }),

                    m(drawn_image,
                        {
                            collection: vnode.state.collection,
                            genre: vnode.attrs.collection
                        }
                    )

                ),

                vnode.state.collection?.quote ? m(quote_bar, { quote: vnode.state.collection.quote }) : [],

                // vnode.state.text ? m(skip_button) : [],

                m('.collection_padding',

                    (vnode.state.text) ? m('.opening_reading',
                        m('p.',
                            m('.',
                                m(main_text, { lines: do_something_to_style(vnode.state.text) })

                            )

                        )
                    ) : [],

                    // m('.d_flex.flex_wrap.flex_gap_extra_large.d_centre.d_centre',
                    m('',
                        (vnode.state.collection?.stories || []).map((choice, i) => m(story_display, { choice: choice, id: i == 0 ? 'first_story' : '' }))
                    )
                ),

                //STORIES
                // m('.d_flex.flex_wrap.flex_gap.d_centre.d_centre',
                //     (vnode.state.collection?.stories || []).map(choice => [
                //         m(Choice, { choice: choice })
                //     ])
                // )


                //AUTHOR
                vnode.state.collection?.authors ? m('.p-10',

                    m('h3.default_h3', 'Popular', (vnode.state.collection.is_genre ? [' ', capitalise_first(vnode.attrs.collection)] : ''), ' Authors'),

                    m('.d_flex.flex_wrap.flex_gap.d_centre',

                        vnode.state.collection?.authors.map(author => m(Author_box, { author_code: author }))
                    )

                ) : [],

                //LINK TO FURTHER READING IF GENRE
                m('.p-10'),

                (vnode.state.collection && vnode.state.collection.is_genre) ? [

                    m('a.heavy_button.centre.mb-10.find_library_button',
                        {
                            href: '/stories?genre=' + vnode.attrs.collection,
                            // rel: 'nofollow'

                        },
                        'Filter the Library for ' + capitalise_first(vnode.attrs.collection),
                        m('img', {
                            src: image_base + 'books-180x180.png',
                            alt: 'A pixel image of differently coloured books.'
                        })
                    ),

                ]

                    : []
            ),
        ]
    }
}

const main_text = {
    view: vnode => {
        return [
            vnode.attrs.lines ? vnode.attrs.lines.map(line =>
                m('p',
                    line.map(phrase =>
                        phrase.is_link ?
                            m('a', {
                                href: phrase.text.split('|')[1],
                                target: phrase.text.split('|')[1].includes('http') ? '_blank' : undefined,
                                class: phrase.text.split('|')[1].includes('http') ? 'external_link' : 'my_link'


                            }, phrase.text.split('|')[0])
                            :
                            phrase.text
                    )
                )
            ) : []
        ]
    }
}

const drawn_image = {
    view: vnode => {
        if (!vnode.attrs.collection || !vnode.attrs.collection.image) return []

        return m('a.drawn_image_link',
            { href: '/onlinereader/' + vnode.attrs.collection.image_code },

            m('img.drawn_image', {
                src: image_base + 'collection-' + vnode.attrs.collection.code + '.jpg',
                style: `filter: ${vnode.attrs.collection.image_filter}`,
                alt: vnode.attrs.collection.image_alt,
                title: vnode.attrs.collection.image_title
            })

        )
    }
}

const triple_image = {
    view: vnode => {
        if (!vnode.attrs.collection || !vnode.attrs.collection.is_genre) return []

        let genre = Genres.list.find(g => g.code == vnode.attrs.genre)

        return [
            m('.new_genre_box_section_1',
                [...Array(3)].map((_, i) => [
                    m('.new_genre_box_image',
                        m('img.', {
                            loading: 'lazy',
                            style: `${Genres.get_style(genre.hue)}; ${(genre.images && genre.images[i].position) ? ('object-position: ' + genre.images[i].position) : ''}`,
                            src: (image_base + (genre.images ? genre.images[i].src : genre.image)) + '.jpg',
                            alt: (genre.images ? genre.images[i].alt : genre.image_alt),
                        }),
                        m('.image_shade', { style: 'background-color:' + genre.colour }),
                        m('.new_genre_box_image_border')
                    )
                ])
            )
        ]
    }
}

export default Page
