import m from 'mithril'
import Stories from '../../models/Stories'
import Genres from '../../models/Genres'
import { capitalise_first, get_publish_date, get_words_info } from '../../logic/Formatter'
import { star_icon } from './icons'

const s3 = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'
const base_image_url_2 = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/covers/small/'

const box = {
    oninit: vnode => {
        Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
            vnode.state.story = story
            m.redraw()
        })
    },

    view: vnode => {

        let story = vnode.state.story || vnode.attrs.story

        if (vnode.attrs.no_scene) return m(box_book, {
            active: vnode.attrs.active,
            flip: vnode.attrs.flip,
            half_flip: vnode.attrs.half_flip,
            story: story,
            code: vnode.attrs.code,
            loading: vnode.attrs.loading,
            show_title: vnode.attrs.show_title,
            show_author: vnode.attrs.show_author,
            show_time: vnode.attrs.show_time,
            hide_back: vnode.attrs.hide_back
        })

        return [
            m("div.scene", {
                style: 'transform: rotateY(' + Math.floor(vnode.attrs.rotate_scene * 360 / 12) + 'deg) translateZ(350px)'
            },
                m(box_book, {
                    active: vnode.attrs.active,
                    flip: vnode.attrs.flip,
                    half_flip: vnode.attrs.half_flip,
                    story: story,
                    code: vnode.attrs.code,
                    loading: vnode.attrs.loading,
                    show_title: vnode.attrs.show_title,
                    title_is_link: vnode.attrs.title_is_link,
                    show_author: vnode.attrs.show_author,
                    show_time: vnode.attrs.show_time,
                    hide_back: vnode.attrs.hide_back
                })
            )
        ]
    }
}

export default box

const box_book = {
    view: vnode => {

        let story = vnode.attrs.story
        return [
            m(".box", {
                class: 'small-tilt' +
                    (vnode.attrs.active ? ' active' : '') +
                    (vnode.attrs.half_flip ? ' half_flip' : '') +
                    (vnode.attrs.flip ? ' flip' : ''),
            },
                [
                    m("div", {
                        "class": "box__face box__face--cover",
                    },

                        story ? m(cover_image, { story: story, loading: vnode.attrs.loading }) : [],

                        (story && !story.unique_cover) ? m(image_shade, {story: story, show_title: vnode.attrs.show_title }) : [],
                        // story ? m(image_shade, { story: story, show_title: vnode.attrs.show_title }) : [],

                        (vnode.attrs.show_time && story) ? m('.scatter_time',
                            [Math.ceil(story.words / 250), ' min']
                        ) : [],

                        m('.scatter_section_2_border'),

                        vnode.attrs.show_title && story ? [
                            m('a.box_title', {
                                href: vnode.attrs.title_is_link !== false ? ('/onlinereader/' + story.code) : undefined
                            },
                                story.title,

                                (vnode.attrs.show_author !== false ? m('.box_author', story.author) : []),
                            )
                        ]

                            : []

                    ),

                    m("div", {
                        "class": "box__face box__face--front",
                    }),
                    m("div", { "class": "box__face box__face--back" },

                        (story && !vnode.attrs.hide_back && vnode.attrs.flip) ? m(back_page, {
                            story: story
                        }) : []

                    ),
                    m("div", { "class": "box__face box__face--right" },
                    ),
                    m("div", { "class": "box__face box__face--left" },
                    ),
                    m("div", { "class": "box__face box__face--top" },
                    ),
                    m("div", { "class": "box__face box__face--bottom" },
                    )
                ]
            )
        ]
    }
}

const back_page = {

    view: vnode => {

        return [

            m('.p-10',


                (!vnode.attrs.story.code || vnode.attrs.story.publish_year) ? m('.', vnode.attrs.story.publish_year ? get_publish_date(vnode.attrs.story) : m('.thin_word_shimmer', '-')) : [],

                m('.p-2'),
                m('.', vnode.attrs.story.words ? [get_words_info(vnode.attrs.story.words)] : m('.thin_word_shimmer', '-')),

                m('.p-2'),
                m('.d_flex.flex_side_gap.flex_wrap.box_genres', (vnode.attrs.story.genres || ['', '']).map(genre =>
                    genre ?
                        m('span',
                            capitalise_first(genre)
                        ) :
                        m('.thin_word_shimmer', '-')
                )),

                m('.p-2'),
                m('.', vnode.attrs.story.rating ? m('.small_rating',
                    m('.stars', [...Array(vnode.attrs.story.rating)].map(() => m(star_icon,)),
                    )
                ) : []
                )
            )
        ]
    }
}


const cover_image = {

    view: vnode => {
        let story = vnode.attrs.story
        if (!story) return

        let image = s3 + story?.image
        if (story.unique_cover) { image = (base_image_url_2 + story.code.replace(/-/g, '_') + '.jpg') }



        let style = (story.genres ? Genres.get_style(Genres.list.find(g => g.code == story?.genres[0]).hue) : '')
        if (story && story.unique_cover) style = story.cover_position ? `object-position: ${story.cover_position}` : ''

        return m('img.box_cover_image', {
            loading: vnode.attrs.loading || 'lazy',
            alt: story.image_alt,
            src: image,
            style: style,
        })
    }
}

const image_shade = {
    view: vnode => {
        let story = vnode.attrs.story

        return m('.image_shade', {
            style: (story?.genres && Genres.list.find(g => g.code == story?.genres[0]) ?

                (vnode.attrs.show_title ?
                    ('background:' + 'linear-gradient(0deg, ' + Genres.list.find(g => g.code == story?.genres[0]).colour + ' 0 60%, black 100%)') :
                    ('background-color: ' + Genres.list.find(g => g.code == story?.genres[0]).colour))
                : undefined
            )
        })
    }
}