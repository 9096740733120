import m from 'mithril'

import Stories from '../../models/Stories'
import Reviews from '../../models/Reviews'

import sharelinks from '../components/sharelinks'

import { make_url_friendly, do_something_to_style, quotify } from '../../logic/Formatter'
import quote_bar from '../components/quote_bar'
import story_display from '../components/story_display'
import breadcrumbs from '../components/breadcrumbs'

const Page = {

    oninit: (vnode) => {

        let load_counter = 0

        Stories.fetch_story_metadata(vnode.attrs.review, (result => {
            vnode.state.story = result
            load_counter++
            if (load_counter == 3) m.redraw()
        }))

        Reviews.load(() => {
            vnode.state.review = Reviews.list.find(s => s.code == vnode.attrs.review)
            if (!vnode.state.review) {
                vnode.state.error = true
            }
            load_counter++
            if (load_counter == 3) m.redraw()
        })

        Reviews.fetchtxt_gzip(vnode.attrs.review, (result => {
            vnode.state.review_text = result
            vnode.state.lines = do_something_to_style(vnode.state.review_text)
            load_counter++
            if (load_counter == 3) m.redraw()
        }))
    },

    view: function (vnode) {

        if (vnode.state.error) {
            return m('.page.review_page',
                m('.p-10',
                    m('h1',
                        m('', '404 - Review not found'),
                        m('a.my_link', {
                            href: '/',
                        }, 'Return Home')
                    )
                )
            )
        }


        return [

            m(breadcrumbs, {
                links: ['/discover', '/reviews'],
                title: vnode.state.story?.title
            }),

            m('.page.review_page',

                m('.max-800',

                    vnode.state.story ? m('h1.default_h1',
                        'Why You Should Read... ',
                        m('a.my_link', { href: '/onlinereader/' + vnode.state.story?.code }, vnode.state.story?.title),
                        ' by ',
                        m('a.my_link', { href: '/author/' + make_url_friendly(vnode.state.story?.author) }, vnode.state.story?.author)
                    )
                        :
                        m('.thin_word_shimmer.default_h1', '-'),

                    m('h2.default_h2', 'Short review by Evan C. Lewis'),

                    vnode.state.review ? m(quote_bar, { quote: { quote: quotify(vnode.state.review.my_quote) } }) : [],


                    vnode.state.review?.spoiler ? m('.spoiler_warning', 'Spoilers') : [],


                    m('.reading_text',

                        vnode.state.lines ? vnode.state.lines.map(line =>
                            m('p',
                                line.map(phrase =>
                                    phrase.is_link ?
                                        m('a', {
                                            href: phrase.text.split('|')[1],
                                            target: phrase.text.split('|')[1].includes('http') ? '_blank' : undefined,
                                            class: phrase.text.split('|')[1].includes('http') ? 'external_link' : 'my_link'


                                        }, phrase.text.split('|')[0])
                                        :
                                        phrase.text
                                )
                            )
                        )
                            : m('.shimmer',
                                [...Array(40)].map(() => [
                                    m('.shimmer_cover')
                                ])
                            )
                    ),

                    vnode.state.review ? m(quote_bar, { quote: { quote: vnode.state.review.quote } }) : [],

                    m('.p-10'),

                    m(story_display, {
                        choice: {
                            story: vnode.attrs.review
                        },
                        // no_section_3: true,
                        no_description: true,
                        no_author: true,
                        no_review_link: true

                    }),
                    // m('.p-10'),
                    // m('a.my_link', { href: '/author/' + Formatter.make_url_friendly(vnode.state.story?.author) }, vnode.state.story?.author),
                    m('.p-10'),

                    m(sharelinks, { link: window.location.href }),
                    m('.p-10')
                )

            )
        ]
    }
}


export default Page



