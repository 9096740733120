import m from 'mithril'
import { number_to_text } from '../../logic/Formatter'

import story_display from '../components/story_display'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'
import quote_bar from '../components/quote_bar'


const Page = {
    view: function (vnode) {

        return [
            m('.page.collection_page',
                {
                    itemscope: true,
                    itemtype: 'https://schema.org/Guide'
                },
                m('h1.default_h1', 'The Classics'),
                m('h2.default_h2', 'History’s ', number_to_text(TITLES.length), ' most recognisable short stories out of copyright.'),

                m(triple_image),


                //Definition of a classic — something that everybody wants to have read and nobody wants to read.
                // m(quote_bar, {quote: {quote: 'Definition of a classic — something that everybody wants to have read and nobody wants to read. - Mark Twain'}}),

                // m(skip_button),
                m(quote_bar, {quote: {quote: '“We live on a placid island of ignorance in the midst of black seas of infinity, and it was not meant that we should voyage far.” * The Call of Cthulhu', code: 'the-call-of-cthulhu'}}),

                m('.collection_padding',


                // m(quote_bar, {quote: {quote: '“If still you think me mad, you will think so no longer when I describe the wise precautions I took for the concealment of the body.” * The Tell-Tale Heart', code: 'the-tell-tale-heart'}}),
                
                    m(opening_text),


                    m('',
                        TITLES.map((choice, i) => [
                            m(story_display, { choice: choice, id: i == 0 ? 'first_story' : '' })

                        ])
                    )
                )
            )
        ]
    }
}

const opening_text = {
    view: vnode => {
        return [
            m('.opening_reading',

                m('p',
                    'Here are the most recognised, referenced and quoted short stories of history. They have inspired thousands of books, shows, and movies and have left a permanent mark on literature.',
                    //                    'While less well known than the most famous novels or movies of all time, the impact these stories have had on literature and art as a whole is undeniable.'
                ),

                // m('p.',
                //     'Some, such as ',
                //     m('i', 'A Scandal In Bohemia'),
                //     ', are the standout works of iconic authors, while others such as ',
                //     m('i', 'The Most Dangerous Game'),
                //     ' are one-hit-wonders. '
                // ),

                // m('p.',

                //     `History has been kind to the dark and dreary. Three of the most well-known short stories of history `,
                //     m('a.my_link', { href: '/onlinereader/the-tell-tale-heart' }, `The Tell-Tale Heart`),
                //     `, `,
                //     m('a.my_link', { href: '/onlinereader/the-yellow-wallpaper' }, `The Yellow Wallpaper`),
                //     `, and `,
                //     m('a.my_link', { href: '/onlinereader/the-call-of-cthulhu' }, `The Call of Cthulhu`),
                //     ` are all gothic tales that dealing with the consequences of insanity.`,


                //     // m('br'),
                //     ` Two more horrors fill out this list as well, including Dickens’s ghost story `,
                //     m('a.my_link', { href: '/onlinereader/the-signal-man' }, `The Signal Man`),
                //     ` and Roald Dahl’s twisted horror `,
                //     m('a.my_link', { href: '/onlinereader/lamb-to-the-slaughter' }, `Lamb to the Slaughter`),
                //     `.`,
                // ),
                // m('p',
                //     `I can’t include  the undoubtedly impactful but still copyrighted `,
                //     m('i', m('a.external_link', { href: 'https://www.newyorker.com/magazine/1948/06/26/the-lottery', target: '_blank' }, `The Lottery`)),
                //     ` by Shirley Jackson.`,
                //     // ),
                //     // m('p',
                //     ` And I'll give a shout out to the wonderfully thought-provoking `,
                //     m('i', m('a.external_link', { href: 'https://www.gutenberg.org/files/5200/5200-h/5200-h.htm', target: '_blank' }, `The Metamorphosis`)),
                //     ` by `,
                //     m('a.my_link', { href: '/author/franzkafka' }, `Franz Kafka`),

                //     `, but despite being often referred to as a short story it is technically a novella at over 20,000 words.`,
                // ),
            ),
        ]
    }
}

const TITLES = [
    {
        story: 'the-tell-tale-heart',
        description: `Edgar Allan Poe was the original champion of gothic horror and of the medium of the short story. He was the first author in America to fully support himself financially purely with his writing. Many of his works have survived, but the paramount story has always been his story of a deranged murderer and his insane plea to explain himself. The *Tell-Tale Heart* has incredible tension and a poetic ending.`

    },
    {
        story: 'a-scandal-in-bohemia',
        description: `Despite Arthur Conan Doyle wanting to go down in history as the next Shakespeare or Poe, and to be known for his literary and gothic stories, his name has become inseparable from Sherlock Holmes. And as much as I would like to respect his wishes, his most famous story is undeniably the first short story of the man of deduction, and the introduction of the only woman to outsmart him.`,
    },
    {
        story: 'the-yellow-wallpaper',
        description: `Charlotte Perkins Gilman gave us one of the hallmark pieces of early feminist literature, based on her own experiences with depression and childbirth. It is believed that her ability to put to pen the insufferable experience of being homebound against one’s will caused many doctor’s practices to abandon the practice of forced isolation altogether.
        A woman, restrained to her house by her husband and sister-in-law, begins obsessing over wallpaper she finds disturbing.
        `,
    },
    {
        story: 'an-occurrence-at-owl-creek-bridge',
        description: `Ambrose Bierce Served in the Union Army during the American Civil War. But like many authors, he wrote afterwards not of victory or of hatred for the other side, but of the pain of war itself. In *An Occurrence at Owl Creek Bridge*, Bierce shows a simple humanity in the other side. It is devoid of what was fought for and what was done to fight for it, and leaves only the personal life and emotion of a soldier about to be executed. It is poetically written and exceedingly tragic.`,
    },
    {
        story: 'the-call-of-cthulhu',
        description: `Like Sherlock Holmes to Arthur Conan Doyle, the character of Cthulhu has a potentially longer-lasting renown than H. P. Lovecraft, having popped up in movies, T.V. videogames, books and tabletop games. The true horror of the monster, however, is best depicted in its original story, where Lovecraft did not merely make a good horror, but reinvented the genre and developed entirely new kinds of fear.`,
        //        description: `A man inherits a manuscript from his uncle, describing cases of insanity and mania sprouting at the same time every year around the globe, along with dreams of a titanic, petrifying ‘Thing’.`
    },
    {
        story: 'the-most-dangerous-game',
        description: `When Richard Connell published *The Most Dangerous Game*, something about the story of a man being hunted by another man as sport struck a nerve in people. You may not have heard of the specific short story, but you have certainly seen works of art whose plot can be drawn back to the original Shipwreck Island.`,
        //        description: `A marooned sailor considers himself lucky to find a house on the island, only to discover he may have been better off drowning at sea.`
    },
    {
        story: 'the-lady-with-the-dog',
        description: `Anton Chekhov is one of the kings of the short story, and the crown jewel of his works is the *Lady With the Dog*. It is considered a story so well-written that it never needed much of a plot. Of course, it was originally written in Russian, and one wonders (at least those of us who can’t read Russian) what could have been lost the translation of this story of two lovers running from their marriages.`

        // An unhappily married man meets a woman walking her dog and falls for her.`
    },
    {
        story: 'the-signal-man',
        description: `Dickens is just too famous to ignore. While he is known for his novels, *The Signal Man* gives a snippet of his famous, ghostly, gothic ethos. It is an eerie and evocative story of a rail signal man who has visions of ghosts trying to warn him of future accidents along the train tracks.`
    },
    {
        story: 'lamb-to-the-slaughter',
        description: `*Lamb to the Slaughter* is by far the most modern short story on this list. It is written by Roald Dahl, the famously macabre and sarcastic children’s author. He keeps the macabre here, but any childishness playfulness is gone, replaced with murder and horror. Nonetheless, this story has spread like wildfire for retaining Dahl’s typical absurd and dauntless style of writing.`,
        //        description: `A woman’s husband admits to her some painful news, she picks up a frozen leg of lamb and delivers some painful news of her own.`
    },

    {
        story: 'desirees-baby',
        description: `*Desiree’s Baby* hits you with two plot twists in a row, and this is undoubtedly one of the reasons it went down in history. The story is poetic and descriptive, but fast paced and quick to set up the twist and nail the reveal.`
    },

]

export default Page




const triple_image = {
    view: vnode => {


        return [
            m('.new_genre_box_section_1',
                [
                    // {
                    //     image: 'stare.jpg',
                    //     colour: 'rgb(87, 87, 87)'

                    // },
                    {
                        image: 'lovers.jpg',
                        alt: 'A kissing man and woman, silhouetted in a forest. Graphite filter. Graphite filter.',
                        hue: '20deg',
                        colour: 'rgb(27, 27, 152)'
                    },
                    {
                        image: 'detective.jpg',
                        hue: '48deg',
                        colour: 'rgb(52, 0, 112)',
                        alt: 'A man in a bowler hat and a popped collar. Graphite filter'

                    },
                    {
                        image: 'creek.jpg',
                        hue: '150deg',
                        alt: 'A rocky creek in a forest.',
                        colour: 'rgb(117, 0, 0)'
                    },

                ].map((story, i) => [
                    m('.new_genre_box_image',
                        m('img.', {
                            loading: 'lazy',
                            style: story.hue ? 'filter: grayscale(1) invert() sepia() saturate(150%) hue-rotate(' + story.hue + ') invert(1) brightness(1.2);' : '',
                            src: image_base + story.image,
                            alt: story.alt,
                        }),
                        m('.image_shade', {
                            style: 'background-color:' + story.colour
                        }),
                        m('.new_genre_box_image_border')
                    )
                ])
            )
        ]
    }
}