// Author -Evan Lewis

// Main js script called by index.html
// Front end framework - Mithril

import m, { route as _route } from 'mithril'
import Menu from "./models/Menu"


import missingpage from './views/pages/404'
import layout from './views/layout'

// import layout from './views/layout'

_route.prefix = ''

var root = document.body

let routes = {}

import _404 from "./views/pages/404.js"
import about from "./views/pages/about.js"
import author from "./views/pages/author.js"
import choice from "./views/pages/choice.js"
import classics from "./views/pages/classics.js"
import collection from "./views/pages/collection.js"
import collections from "./views/pages/collections.js"
import discover from "./views/pages/discover.js"
import five_minute_stories from "./views/pages/five-minute-stories.js"
import fifteen_minute_stories from "./views/pages/fifteen-minute-stories.js"
import furtherreading from "./views/pages/furtherreading.js"
import genres from "./views/pages/genres.js"
import how_to_use from "./views/pages/how-to-use.js"
import about_the_stories from "./views/pages/about-the-stories"
import privacy from "./views/pages/privacy.js"
import review from "./views/pages/review.js"
import reviews from "./views/pages/reviews.js"
import series from "./views/pages/series.js"
// import experiment from "./views/pages/experiment"

let pages = {
    '/404': _404,
    '/about': about,
    '/author': author,
    '/choice': choice,
    '/classics': classics,
    '/collection': collection,
    '/collections': collections,
    '/discover': discover,
    '/five-minute-stories': five_minute_stories,
    '/fifteen-minute-stories': fifteen_minute_stories,
    '/furtherreading': furtherreading,
    '/genres': genres,
    '/how-to-use': how_to_use,
    '/about-the-stories': about_the_stories,
    '/privacy': privacy,
    '/review': review,
    '/reviews': reviews,
    '/series':series,
    // '/experiment': experiment,
}

//Here I dynamically loop through an array of pages and import them.
//It's probably not the proper way to do it but I like automating everything :)

Menu.get_pages()
    .filter(p => p.file !== false)
    .forEach(page => {

        let view = pages[page.route]

        let route = page.route
        if (page.subroute) { route += page.subroute }


        routes[route] =
        {
            render: function (vnode) {
                return (
                    m(layout, m(view, vnode.attrs))
                )
            }
        }
    })


//404, doesn't override other urls because it is set after the others
//DONT MOVE ABOVE OTHER ROUTES OR IT WILL ACTIVATE FIRST AND EVERYTHING WILL BE 404

routes['/:*'] = {
    render: vnode => m(layout, m(missingpage))
}
routes['/:*/:*'] = {
    render: vnode => m(layout, m(missingpage))
}


//Set all routes
//Home is default (libraryofalexandria.com -> /)
_route(root, "/", routes);